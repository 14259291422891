<template lang="html">
  <div id="cases-list-page">

    <section class="container top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <div class="box-title-s2">
            <h1 class="title">MIS CASOS</h1>
          </div>
        </div>

        <div class="col-lg-7 col-search">
          <b-form @submit="onSubmit">
            <b-form-input
              v-model="formSearch.keywords"
              type="text"
              placeholder="BUSCAR CASO"
              required
            ></b-form-input>

            <b-button type="submit" class="t-250 btn-search"><i class="fas fa-search"></i></b-button>
          </b-form>
        </div>
      </div>
    </section>

    <section class="container cases-section">
      <div class="row">

        <div class="col-lg-12 col-case" v-for="(c, cInx) in cases" :key="'cInx-'+cInx">
          <router-link class="box" :to="'/casos/'+c.id">
            <div class="col-10 col-notification">
              <h6 v-if="c.accused_id == $root.user.id">Demandado</h6>
              <h6 v-else>Demandante</h6>
              <!-- <h6><i class="far fa-bell icon"></i> NOTIFICACIONES ({{ c.notifications }})</h6> -->
            </div>
            <div class="col-2 col-notification">
              <h6>{{c.status}}</h6>
              <h6 style="font-family: sans-serif;"><i class="far fa-bell icon"></i> ({{ c.notifications }})</h6>
            </div>

            <div class="box-case-info">
              <div class="col-lg-6 col-info">
                <div class="tb">
                  <div class="lab">ID</div>
                  <div class="txt">{{ c.idcustom }}</div>
                  <br><br>
                  <div class="txt" v-html="c.demanding"></div>
                  <div class="txt" style="padding-left: 10px;padding-right: 10px;"> vs </div>
                  <div class="txt" v-html="c.accused"></div>
                </div>
              </div>

              <div class="col-lg-6 col-info">
                <div class="tb">
                  <div class="lab date">FECHA DE INICIO</div>
                  <div class="txt">{{c.created_date}} <span style="color:gray;">{{c.created_hour}}</span></div>
                </div>
              </div>

              <div class="col-lg-12 col-info">
                <div class="tb">
                  <div class="lab">Tipo</div>
                  <div class="txt">{{c.typecustom}}</div>
                </div>
              </div>

              <div class="col-lg-12 col-info">
                <div class="tb">
                  <div class="lab">ESTATUS</div>
                  <div class="txt status">{{c.fase}}</div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formSearch: {
        keywords: null
      },

      cases: [
        /*{ notification: 1, id: '#21235' },
        { notification: 0, id: '#21236' },
        { notification: 2, id: '#21238' },
        { notification: 1, id: '#21240' },
        { notification: 1, id: '#21242' },
        { notification: 1, id: '#21249' },*/
      ]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.formSearch))
    },

    getData(){
        axios.get(tools.url("/api/casees/"+this.$root.user.id)).then((response)=>{
            this.cases = response.data;
        }).
        catch(()=>{
        });
    }
  },
  mounted(){
      this.getData();
  }
}
</script>
