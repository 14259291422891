<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <b-form class="row mx-0 flex-center-xy pt-5 page-size" @submit="onSubmit">

        <div class="form-container pt-5 w-100">
          <div class="box-color"><i><u class="fas fa-folder"></u></i></div>

          <div class="form">
            <!-- Paso 1 -->
            <div v-if="step == 1">
              <div class="d-block mb-3 text-center">
                <h1 class="mb-2 md">Iniciar un proceso</h1>
                <h5 class="f-w-600 txt-blue">1. Registra tus datos</h5>
              </div>

              <h6 class="mb-1 f-w-700 f-s-1-1 txt-black">LLENA TU INFORMACIÓN</h6>
              <hr class="mt-1 hr-black" />

              <div class="row pt-1">
                <b-form-group class="col-lg-12 custom-group-s1 c-radios-group-s1" label="Selecciona el tipo de proceso a iniciar" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    class="radios"
                    v-model="form.type"
                    :aria-describedby="ariaDescribedby"
                    required
                    name="radio-sub-component">
                    <b-form-radio class="col-lg-4" value="arbitration">Arbitraje</b-form-radio>
                    <b-form-radio class="col-lg-4 my-1 my-lg-0" value="mediation">Mediación</b-form-radio>
                    <b-form-radio class="col-lg-4" value="conciliation">Conciliación</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Nombre(s)">
                  <b-form-input type="text" v-model="form.demanding.name" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Apellidos">
                  <b-form-input type="text" v-model="form.demanding.lastname" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Correo electrónico">
                  <b-form-input type="email" v-model="form.demanding.email" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Nombre de la empresa">
                  <b-form-input type="text" v-model="form.demanding.company_name" placeholder=""></b-form-input>
                </b-form-group>


                <b-form-group class="col-lg-6 custom-group-s1" label="Teléfono de contacto">
                  <b-form-input type="text" v-model="form.demanding.phone" required placeholder="" minlength="10" maxlength="10"></b-form-input>
                </b-form-group>
                <div class="col-lg-6 custom-group-s1"></div>

                <b-form-group class="col-lg-6 custom-group-s1" label="Dirección">
                  <b-form-input type="text" v-model="form.demanding.street" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-3 custom-group-s1" label="Numero exterior">
                  <b-form-input type="text" v-model="form.demanding.num_ext" required placeholder=""></b-form-input>
                </b-form-group>
                
                <b-form-group class="col-lg-3 custom-group-s1" label="Numero interior">
                  <b-form-input type="text" v-model="form.demanding.num_int" placeholder=""></b-form-input>
                </b-form-group>




                <b-form-group class="col-lg-6 custom-group-s1" label="Colonia">
                  <b-form-input type="text" v-model="form.demanding.neighborhood" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-3 custom-group-s1" label="Código postal">
                  <b-form-input type="number" v-model="form.demanding.zipcode" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Estado">
                  <b-form-select required v-model="form.demanding.state_id">
                    <b-form-select-option :value="null">Selecciona una opción</b-form-select-option>
                    <b-form-select-option :value="s.id" v-for="(s, srInx) in states" :key="'srInx-'+srInx">{{ s.name }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Ciudad">
                  <b-form-select required v-model="form.demanding.town_id">
                    <b-form-select-option :value="null">Selecciona una opción</b-form-select-option>
                    <b-form-select-option :value="t.id" v-for="(t, srInx) in towns" :key="'srInx-'+srInx" >{{ t.name }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <div class="d-inline-block mb-3 col-lg-12 custom-group-s1" v-if="form.type == 'arbitration'">
                  <b-form-checkbox v-model="form.check_process" name="usertype" :value="true" :unchecked-value="false" required>
                    Comprendo que el contrato que deseas someter a arbitraje debe de contener la cláusula arbitral emitida por el C.A.E. , de otra manera este proceso no será valido.
                  </b-form-checkbox>
                </div>

                
              </div>
            </div>
            <!--  -->

            <!-- Paso 2 -->
            <div v-if="step == 2">
              <div class="d-block mb-3 text-center">
                <h1 class="mb-2 md">Iniciar un proceso</h1>
                <h5 class="f-w-600 txt-blue">2. Proceso de {{form.typenameliststep}}</h5>
              </div>

              <h6 class="mb-1 f-w-700 f-s-1-1 txt-black">SOBRE EL DEMANDADO</h6>
              <hr class="mt-1 hr-black" />

              <div class="row pt-1">
                <b-form-group class="col-lg-6 custom-group-s1" label="Nombre(s)">
                  <b-form-input type="text" v-model="form.accused.name" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Apellidos">
                  <b-form-input type="text" v-model="form.accused.lastname" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Correo electrónico">
                  <b-form-input type="email" v-model="form.accused.email" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Nombre de la empresa">
                  <b-form-input type="text" v-model="form.accused.company_name" placeholder=""></b-form-input>
                </b-form-group>


                

                <b-form-group class="col-lg-6 custom-group-s1" label="Teléfono de contacto">
                  <b-form-input type="text" v-model="form.accused.phone" required placeholder="" minlength="10" maxlength="10"></b-form-input>
                </b-form-group>

                <div class="col-lg-6 custom-group-s1"></div>

                <b-form-group class="col-lg-6 custom-group-s1" label="Dirección">
                  <b-form-input type="text" v-model="form.accused.street" required placeholder=""></b-form-input>
                </b-form-group>

                 <b-form-group class="col-lg-3 custom-group-s1" label="Numero exterior">
                  <b-form-input type="text" v-model="form.accused.num_ext" required placeholder=""></b-form-input>
                </b-form-group>
                
                <b-form-group class="col-lg-3 custom-group-s1" label="Numero interior">
                  <b-form-input type="text" v-model="form.accused.num_int" placeholder=""></b-form-input>
                </b-form-group>



                <b-form-group class="col-lg-6 custom-group-s1" label="Colonia">
                  <b-form-input type="text" v-model="form.accused.neighborhood" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-3 custom-group-s1" label="Código postal">
                  <b-form-input type="number" v-model="form.accused.zipcode" required placeholder=""></b-form-input>
                </b-form-group>

                <div class="col-lg-9"></div>

                <b-form-group class="col-lg-6 custom-group-s1" label="Estado">
                  <b-form-select required v-model="form.accused.state_id">
                    <b-form-select-option :value="null">Selecciona una opción</b-form-select-option>
                    <b-form-select-option :value="s.id" v-for="(s, srInx) in states" :key="'srInx-'+srInx">{{ s.name }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Ciudad">
                  <b-form-select required v-model="form.accused.town_id">
                    <b-form-select-option :value="null">Selecciona una opción</b-form-select-option>
                    <b-form-select-option :value="t.id" v-for="(t, srInx) in towns2" :key="'srInx-'+srInx" >{{ t.name }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

              </div>
            </div>
            <!--  -->

            <!-- Paso 3 -->
            <div v-if="step == 3">
              <div class="d-block mb-3 text-center">
                <h1 class="mb-2 md">Iniciar un proceso</h1>
                <h5 class="f-w-600 txt-blue">3. Proceso de {{form.typenameliststep}}</h5>
              </div>

              <h6 class="mb-1 f-w-700 f-s-1-1 txt-black">SOBRE EL CASO</h6>
              <hr class="mt-1 hr-black" />

              <div class="row pt-1">
                <b-form-group class="col-lg-6 custom-group-s1" label="Monto demandado o en pleito">
                  <!-- <b-form-input type="text" v-model="form.amount" required placeholder=""></b-form-input> -->
                 <currency-input id="i-co" class="form-control" v-model="form.amount" placeholder="$0.00" required min="1" /> 

                </b-form-group>
                <div class="col-lg-6 custom-group-s1"></div>
                <b-form-group class="col-lg-6 custom-group-s1" label="Comision a pagar al C.A.E. (5% del monto demandado)">
                 <currency-input id="i-co" class="form-control" v-model="form.commission_cae" placeholder="$0.00" required min="1" disabled/> 
                </b-form-group>
                <div class="col-lg-6 custom-group-s1"></div>
                <b-form-group class="col-lg-6 custom-group-s1" label="Gastos administrativos">
                 <currency-input id="i-co" class="form-control" v-model="form.administrative_fee" placeholder="$0.00" required min="1" disabled/> 
                </b-form-group>
                <div class="col-lg-6 custom-group-s1"></div>

                <b-form-group class="col-lg-6 custom-group-s1" label="Total a pagar por iniciacion de proceso al C.A.E. posteriormente pueden ser rembolsados">
                 <currency-input style="background-color: #38c538; font-weight: 700;" id="i-co" class="form-control" v-model="form.total" placeholder="$0.00" required min="1" disabled/> 
                </b-form-group>
                <div class="col-lg-6 custom-group-s1"></div>


                <!-- <b-form-group class="col-lg-6 custom-group-s1" :label="'Selecciona un '+form.typename+' de la lista de '+form.typenamelist+' acreditados por el CAE'">
                  <b-form-select required v-model="form.referees_id">
                    <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                     <b-form-select-option :value="s.id" v-for="(s, srInx) in referees" :key="'srInx-'+srInx">{{ s.name }}</b-form-select-option>

                    

                   
                  </b-form-select>
                </b-form-group> -->

                <div class="d-inline-block mb-3 col-lg-12 custom-group-s1">
                  <b-form-checkbox v-model="form.propose_arbitro" name="usertype" :value="true" :unchecked-value="false">
                    Quiero proponer un {{form.typename}}
                  </b-form-checkbox>
                </div>

                <b-form-group class="col-lg-6 custom-group-s1" label="Nombre(s)" v-if="form.propose_arbitro == true">
                  <b-form-input type="text" v-model="form.arbitro.name" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Cedula profesional" v-if="form.propose_arbitro == true">
                  <b-form-input type="text" v-model="form.arbitro.identification_card" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Correo electrónico" v-if="form.propose_arbitro == true">
                  <b-form-input type="email" v-model="form.arbitro.email" placeholder=""></b-form-input>
                </b-form-group>
                
                <b-form-group class="col-lg-6 custom-group-s1" label="Teléfono de contacto" v-if="form.propose_arbitro == true">
                  <b-form-input type="text" v-model="form.arbitro.phone" placeholder=""></b-form-input>
                </b-form-group>



         

                <b-form-group class="col-lg-12 custom-group-s1" label="Descripcion de la demanda">
                  <b-form-textarea
                    v-model="form.litigation_points"
                    placeholder=""
                    rows="4"
                    max-rows="4"
                    required
                    style="height: 400px;"
                  ></b-form-textarea>
                </b-form-group>

                <div class="d-inline-block mb-3">
                <b-form-checkbox v-model="form.wish_exercise_precautionary" name="usertype" :value="true" :unchecked-value="false" >
                  Deseo ejercer una medida precautoria.
                </b-form-checkbox>
              </div>

              </div>
            </div>
            <!--  -->

            <!-- Paso 4 (pago) -->
            <div v-if="step == 4">
              <div class="d-block mb-3 text-center">
                <h1 class="mb-2 md">Iniciar un proceso</h1>
                <h5 class="f-w-600 txt-blue">4. Realiza el pago por el proceso inicial</h5>
              </div>

              <h6 class="mb-1 f-w-700 f-s-1-1 txt-black">COMPLETA EL FORMULARIO</h6>
              <hr class="mt-1 hr-black" />

              <div class="row pt-1">
                <div class="col-lg-6 text-center text-lg-left mb-2 mb-lg-3">
                  <h6 class="f-w-600 txt-black">TOTAL A PAGAR </h6>
                  <h5 class="f-w-700 txt-blue">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.total) }} MXN</h5>
                </div>

                <div class="col-lg-6 mb-3 mb-lg-0 text-center text-lg-right">
                  <img class="img-fluid" style="max-height: 30px;" src="public/images/shared/payments.svg">
                </div>

                <b-form-group class="col-lg-6 custom-group-s1" label="Nombre en la tarjeta">
                  <b-form-input name="carddname" type="text" v-model="form.cardNombre" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 custom-group-s1" label="Número de la tarjeta">
                  <b-form-input name="card" type="text"  v-model="form.cardNumeros" required minlength="16" maxlength="16" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-sm-6 col-lg-3 custom-group-s1 group-payment-exp" label="Fecha de expiración">
                  <b-form-select class="month" required v-model="form.cardMes">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="mInx + 1" v-for="(m, mInx) in 12" :key="'mes-'+mInx">{{ mInx + 1 }}</b-form-select-option>
                  </b-form-select>

                  <div class="col sep">/</div>

                  <b-form-select class="year" required v-model="form.cardAnio">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="currentYear + yInx" v-for="(y, yInx) in 10" :key="'anio-'+yInx">{{ currentYear + yInx }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group class="col-sm-6 col-lg-3 custom-group-s1" label="CVV">
                  <b-form-input type="password" name="cvv" v-model="form.cardCVV" required placeholder="" minlength="3" maxlength="4"></b-form-input>
                </b-form-group>
              </div>


              <div class="d-inline-block mb-3">
                <b-form-checkbox v-model="form.tos" name="usertype" value="1" unchecked-value="" required>
                  Acepto el <a href="https://cae.com.mx/#/reglamento-de-mediacion-del-cae" target="_blank">REGLAMENTO DE MEDIACION DEL CAE</a>, <a href="https://cae.com.mx/#/reglamento-de-arbitraje-expedito-y-de-baja-cuantia" target="_blank">REGLAMENTO DE ARBITRAJE EXPEDITO Y DE BAJA CUANTIA</a> el  y el <a href="https://cae.com.mx/#/arancel-de-honorarios-de-arbitraje-del-cae" target="_blank">ARANCEL DE HONORARIOS DE ARBITRAJE DEL CAE</a>.
                </b-form-checkbox>
              </div>
            </div>
            <!--  -->

            <!-- Paso 5 -->
            <div v-if="step == 5">
              <div class="d-block pb-2 text-center">
                <h2 class="mb-2 f-w-600 txt-blue">¡Gracias, has iniciado un proceso de {{form.typenameliststep}}!</h2>

                <h3 class="txt-black f-w-600">ID CAE000{{order_id}}</h3>

               <!--  <h6 class="mt-4">Tu caso con el título</h6>
                <h5 class="f-w-600 txt-blue-2">Título de éste caso</h5> -->

                <h6 class="mt-4">Estatus</h6>
                <h5 class="f-w-600 txt-blue-2">En proceso</h5>

                <h6 class="mt-4 txt-black">Recibirás notificaciones en tu correo de:</h6>
                <h5 class="f-s-1-1 f-w-600 txt-black"><i>notificaciones@cae.com.mx</i></h5>

                <h6 class="mt-4 txt-black">Cualquier duda comunícate a:</h6>
                <h5 class="f-s-1-1 f-w-600 txt-black"><i>ayuda@cae.com.mx</i></h5>

                <!-- <router-link class="btn btn-blue px-5 mt-3 f-w-500" to="/">REGRESAR</router-link> -->
              </div>
            </div>
            <!--  -->
          </div>

          <!-- Errors -->
          <div class="mb-4 form-errors" v-if="formErrors.length">
            <hr />
            <ul>
              <li class="text-danger" v-for="formError in formErrors">
                <small>{{ formError }}</small>
              </li>
            </ul>
          </div>
          <!--  -->
        </div>

        <div class="col-12 px-0">
          <b-form-group class="text-right mt-3">
            <a class="btn btn-blue outline mr-2" @click="goPrev" v-if="step > 1 && step <= 4">Anterior</a>
            <b-button type="submit" class="btn btn-blue" v-if="step <= 3">Siguiente</b-button>
            <b-button type="submit" class="btn btn-green" v-if="step == 4">Pagar e iniciar el proceso</b-button>
          </b-form-group>
        </div>
      </b-form>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formErrors: [],

      step: 1,
      currentYear: 0,

      form: {
        demanding:{
            name:null,
            lastname:null,
            email:null,
            phone:null,
            street:null,
            neighborhood:null,
            zipcode:null,
            state_id:null,
            town_id:null,
            company_name:null,

        },
        accused:{
            name:null,
            lastname:null,
            company_name:null,
            email:null,
            phone:null,
            street:null,
            neighborhood:null,
            zipcode:null,
            state_id:null,
            town_id:null,
        },
        check_process:null,
        type:null,
        typename:null,
        typenamelist:null,
        typenameliststep:null,

        amount:null,
        litigation_points:null,
        referees_id:null,
        propose_arbitro:false,
        arbitro:{
            name:null,
            professional_license:null,
            email:null,
            phone:null,
        },

        cardMes: null,
        cardAnio: null,
        commission_cae:0,
        administrative_fee:0,
        total:0,
        wish_exercise_precautionary:false
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      states: [],
      towns: [],
      towns2:[],
      referees:[],

      order_id:null
    }
  },
  watch:{
    'form.type':function(val){
        if (val == 'arbitration') {
          this.form.typename = 'árbitro';
          this.form.typenamelist = 'Árbitros';
          this.form.typenameliststep = 'arbitraje';
          this.getReferees('referees');
        }
        else if (val == 'mediation') {
          this.form.typename = 'Mediador';
          this.form.typenamelist = 'Mediadores';
          this.form.typenameliststep = 'mediación';
          this.getReferees('mediators');
        } 
        else if (val == 'conciliation') {
          this.form.typename = 'Conciliador';
          this.form.typenamelist = 'Conciliadores';
          this.form.typenameliststep = 'conciliación';
          this.getReferees('conciliator');
        }
        this.form.referees_id = null;
        
    },
    'form.demanding.state_id':function (val) {
        this.getTowns();

    },
    'form.accused.state_id':function(val){
        this.getTowns2();
    },
    'form.amount':function(val){
        this.form.commission_cae = this.form.amount * .05;
        if(this.form.amount <= 250000){
            this.form.administrative_fee = 10000;
        }
        else if(this.form.amount > 250000 || this.form.amount <= 500000){
            this.form.administrative_fee = 20000;
        }
        else if(this.form.amount > 500000 || this.form.amount <= 750000){
            this.form.administrative_fee = 30000;
        }
        else if(this.form.amount > 750000 || this.form.amount <= 1000000){
            this.form.administrative_fee = 40000;
        }
        else if(this.form.amount > 1000000 || this.form.amount <= 1500000){
            this.form.administrative_fee = 50000;
        }
        else if(this.form.amount > 1500000 || this.form.amount <= 2000000){
            this.form.administrative_fee = 60000;
        }
        else if(this.form.amount > 2000000 || this.form.amount <= 5000000){
            this.form.administrative_fee = 80000;
        }
        else if(this.form.amount > 5000000 || this.form.amount <= 10000000){
            this.form.administrative_fee = 100000;
        }
        else if(this.form.amount > 10000000 || this.form.amount <= 40000000){
            this.form.administrative_fee = 150000;
        }
        else if(this.form.amount > 40000000 || this.form.amount <= 80000000){
            this.form.administrative_fee = 200000;
        }
        else if(this.form.amount > 80000000){
            this.form.administrative_fee = 300000;
        }

        this.form.total = this.form.commission_cae + this.form.administrative_fee;
    },
  },

  methods: {
    goPrev(){
      this.step = this.step - 1;
    },

    onSubmit(event) {
      event.preventDefault();

      if(this.step == 4){
        this.saveForm();
        
      }
      if(this.step == 3){
        this.step = 4;
      }
      if(this.step == 2){
        this.step = 3;
      }
      if(this.step == 1){
        this.step = 2;
      }

      this.formErrors = [];
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="Error desconocido.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.demanding.state_id){
        axios.get(tools.url('/api/towns/' + this.form.demanding.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },
    getTowns2(){
      if(this.form.accused.state_id){
        axios.get(tools.url('/api/towns/' + this.form.accused.state_id)).then((response)=>{
          this.towns2 = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },
    getReferees(type){
        axios.get(tools.url('/api/referees/'+type)).then((response)=>{
          this.referees = response.data;
        }).catch((error)=>{
          console.log(error);
        });
    },
    saveForm(){
         this.modal.block = true;
          this.modal.icon = '';
          this.modal.msg = 'Guardando información. por favor espere...';
          this.$refs.modal.open();
        axios.post(tools.url('/api/casees'),this.form).then((response)=>{
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "";
            this.modal.block = false;
            this.$refs.modal.close();

            this.order_id = response.data.id;
            this.step = 5;
        }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = response.data.message;
            this.modal.block = false;
        });
        
    }
  },

  mounted(){
    this.getStates();
    
    this.form.demanding.name = this.$root.user.name;
    this.form.demanding.lastname = this.$root.user.lastname;
    this.form.demanding.email = this.$root.user.email;
    this.form.demanding.company_name = this.$root.user.company_name;
    this.form.demanding.phone = this.$root.user.phone;
    this.form.demanding.phone = this.$root.user.phone;
    this.form.demanding.street = this.$root.user.address.street;
    this.form.demanding.num_ext = this.$root.user.address.num_ext;
    this.form.demanding.num_int = this.$root.user.address.num_int;
    this.form.demanding.neighborhood = this.$root.user.address.neighborhood;
    this.form.demanding.zipcode = this.$root.user.address.zipcode;
    this.form.demanding.state_id = this.$root.user.address.state_id;
    this.form.demanding.town_id = this.$root.user.address.town_id;
    
    this.currentYear = new Date().getFullYear();
  }
}
</script>
