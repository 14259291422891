<template lang="html">
  <div class="box-loading-s1">
    <div class="wrapper">
      <span class="circle circle-1"></span>
      <span class="circle circle-2"></span>
      <span class="circle circle-3"></span>
      <span class="circle circle-4"></span>
      <span class="circle circle-5"></span>
      <span class="circle circle-6"></span>
      <span class="circle circle-7"></span>
      <span class="circle circle-8"></span>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
