<template lang="html">
  <div class="page-content box-messages-template">
    <loading-component v-if="isLoading"></loading-component>

    <div v-else>
      <div class="box-arbitro">
        <div class="col-3 col-arbitro-info" v-for="(usr,indx) in users">
          <div class="col col-photo">
            <div class="placed-backg box-photo" v-bind:style="{ backgroundImage: 'url('+usr.imageUrl+')' }"></div>
          </div>

          <div class="col col-text">
            <h6>{{usr.typeuser}}</h6>
            <h5>{{usr.name}} {{usr.lastname}}</h5>
          </div>
        </div>
      </div>

      <div class="box-form">
        <div class="col-12 col-form">
          <b-form @submit="onSubmit">

            <!-- Archivo -->
            <!-- <div v-if="isFormFile">
              <div class="box-shad">
                <div class="col-12 py-3">
                  <b-form-file v-model="form.file" id="file-small" plain></b-form-file>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-lg-9">
                  <h6 class="pl-1">
                    <a class="t-250 btn-file" @click="toggleType('text')"><i class="fas fa-text-size"></i> <span class="f-w-600">Enviar texto</span></a>
                  </h6>
                </div>

                <div class="col-lg-3">
                  <p class="text-right">
                    <b-button type="submit" class="btn-send">Enviar</b-button>
                  </p>
                </div>
              </div>
            </div> -->
            <!--  -->

            <!-- Texto -->
            <div>
              <div class="box-shad">
                <b-form-textarea
                  v-model="form.message"
                  placeholder="Escribir el mensaje"
                  rows="3"
                  max-rows="3"
                ></b-form-textarea>
              </div>

              <div class="row align-items-center">
                <!-- <div class="col-lg-9 py-2">
                  <h6 class="pl-1">
                    <a class="t-250 btn-file" @click="toggleType('file')"><i class="fas fa-upload"></i> <span class="f-w-600">Enviar archivo</span></a>
                  </h6>
                </div> -->

                <div class="col-lg-3">
                  <p class="text-right">
                    <b-button type="submit" class="btn-send">Enviar</b-button>
                  </p>
                </div>
              </div>
            </div>
            <!--  -->

          </b-form>
        </div>
      </div>

      <div class="box-messages">
        <div class="col-12 col-msg">
          <div class="overflow">
            <div class="box-msg" v-bind:class="{ 'me' : msg.users_id != $root.user.id }" v-for="(msg, msgInx) in messages" :key="'msgInx-'+msgInx">
              <div style="display: flex;font-size: 12px;" v-if="msg.users_id != $root.user.id ">{{msg.username}}</div>
              <div class="bubble" >
                <div class="date">{{msg.created}}</div>

                <span>{{ msg.msg }}</span>
              </div>

              <!-- <a class="bubble" download target="_blank" v-if="msg.type == 'file'" href="#">
                <div class="date">Hoy, 12:23 PM</div>

                <span><i class="fas fa-file-archive mr-1"></i> nombre_de_archivo.pdf</span>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingComponent from '../shared/loading-component-s1.vue'
export default {
  components: {
    loadingComponent
  },

  props: {
    caseid: {
      default: null
    }
  },

  data(){
    return{
      isLoading: true,

      isFormFile: false,

      form: {
        type: 'text',
        message: null
      },

      messages: [
        /*{ user: 'me', type: 'text', text: 'Hola Jesus, ¿Cómo estás?' },
        { user: 'ot', type: 'text', text: 'Hola, Alan. Bién ¿tu que tal?' },
        { user: 'ot', type: 'text', text: '¿A que horas es la junta?' },
        { user: 'me', type: 'file', text: '¿A que horas es la junta?' },*/
      ],
      users:[]
    }
  },

  methods: {
    toggleType(type){
      if(type == 'text'){
        this.isFormFile = false;
        this.form.type = 'text';
      }
      if(type == 'file'){
        this.isFormFile = true;
        this.form.type = 'file';
      }
    },

    onSubmit(event) {
      event.preventDefault();
      var dataform = {
          'casses_id':this.caseid,
          'users_id':this.$root.user.id,
          'msg':this.form.message

      };
        axios.post(tools.url("/api/messages"),dataform).then((response)=>{
            this.getMessages();
        }).
        catch(()=>{
        });


      

      this.form.message = null;
    },

    getMessages(){
        axios.get(tools.url("/api/messages/"+this.caseid)).then((response)=>{
            this.messages = response.data.messages;
            this.users = response.data.users;
            this.isLoading = false
        }).
        catch(()=>{
        });
    },

  },

  mounted(){
    this.isLoading = true;
    let caseID = this.caseid; // Obtenido como "props"
    this.getMessages()
     //setTimeout(()=> { this.isLoading = false }, 1000); // REMOVER ESTO, ES SOLO EJEMPLO
  },
}
</script>
