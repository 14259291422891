<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container w-100">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="register">

            <h1 class="mb-3">Afíliate al CAE como profesionista</h1>

            <div class="row pt-1">
              <b-form-group class="col-lg-6">
                <b-form-input type="text" v-model="form.name" required placeholder="Nombre"></b-form-input>
              </b-form-group>

              <b-form-group class="col-lg-6">
                <b-form-input type="text" v-model="form.lastname" required placeholder="Apellidos"></b-form-input>
              </b-form-group>

              <b-form-group class="col-lg-6">
                <b-form-input type="email" v-model="form.email" required placeholder="Correo electrónico"></b-form-input>
              </b-form-group>

              <b-form-group class="col-lg-6">
                <b-form-input type="text" v-model="form.identification_card" required placeholder="Cédula profesional"></b-form-input>
              </b-form-group>

              <b-form-group class="col-lg-6">
                <b-form-input type="text" v-model="form.phone" required placeholder="Teléfono de contacto" minlength="10" maxlength="10"></b-form-input>
              </b-form-group>

              <b-form-group class="col-lg-6">
                <b-form-select required v-model="form.type_user">
                  <b-form-select-option :value="null" disabled>Especialidad</b-form-select-option>
                  <b-form-select-option value="referees">Arbitro</b-form-select-option>
                  <b-form-select-option value="mediators">Mediador</b-form-select-option>
                  <b-form-select-option value="conciliator">Conciliador</b-form-select-option>
                  <b-form-select-option value="perito">Perito</b-form-select-option>
                  <!-- <b-form-select-option value="agreement_secretaries">Secretarios de acuerdo</b-form-select-option>
                  <b-form-select-option value="perito">Perito</b-form-select-option> -->
                  
                </b-form-select>
              </b-form-group>

              <b-form-group class="col-lg-6">
                <b-form-select required v-model="form.state_id">
                  <b-form-select-option :value="null" disabled>Selecciona un Estado</b-form-select-option>
                  <b-form-select-option :value="s.id" v-for="(s, srInx) in states" :key="'srInx-'+srInx">{{ s.name }}</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group class="col-lg-6">
                <b-form-select required v-model="form.town_id">
                  <b-form-select-option :value="null" disabled>Selecciona un Ciudad</b-form-select-option>
                  <b-form-select-option :value="t.id" v-for="(t, srInx) in towns" :key="'srInx-'+srInx" >{{ t.name }}</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group class="col-lg-12">
                <b-form-textarea
                  v-model="form.comments"
                  required
                  placeholder="Comentarios"
                  rows="4"
                  max-rows="4"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="d-inline-block mb-3">
              <b-form-checkbox v-model="form.tos" name="usertype" value="1" unchecked-value="" required>
                Acepto los términos y condiciones
              </b-form-checkbox>
            </div>

            <div class="mb-4 form-errors" v-if="formErrors.length">
              <hr />
              <ul>
                <li class="text-danger" v-for="formError in formErrors">
                  <small>{{ formError }}</small>
                </li>
              </ul>
            </div>

            <b-form-group class="mt-3">
              <b-button type="submit" class="btn-blue" variant="primary">Afiliarse al CAE</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        {{modal.msg}}
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">Aceptar</b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formErrors: [],

      form: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        password_confirmation: '',
        state_id: null,
        town_id: null,
        type_user:null
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      states: [],
      towns: [],
    }
  },
  watch:{
   
    'form.state_id':function (val) {
        this.getTowns();

    },
    
  },

  methods: {
    register(evt){
      this.formErrors = [];

          this.modal.block = true;
          this.modal.icon = '';
          this.modal.msg = 'Guardando información. por favor espere...';
          this.$refs.modal.open();
        axios.post(tools.url('/api/referees'),this.form).then((response)=>{
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "Gracias por tu interes en afiliarte al CAE. Revisaremos tu información y te contactaremos para requerir algunos documentos y firmar contrato por medio de nuestra tecnologia de NOTARIONET.";
            this.modal.block = false;
            

        }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = response.data.message;
            this.modal.block = false;
        });
        
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="Error desconocido.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.state_id){
        axios.get(tools.url('/api/towns/' + this.form.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },

  },

  mounted()
  {
    this.getStates();
    // if(this.$root.logged){ // **** DESCOMENTAR ESTO AL CONECTAR! ******
    //     this.$router.push("/usuario");
    // }
  }
}
</script>
