<template lang="html">
  <div id="blog-page">
    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-12">
          <div class="box-title-s2 col-page-title">
            <h1 class="title">COMUNICADOS</h1>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3 col-article article-sample-1" v-for="(article, ahInx) in blog" :key="'ahInx-'+ahInx">
          <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" to="/comunicados/1">
            <img class="empty" src="public/images/shared/empty.png">

            <div class="inside">
              <div class="t-250 show">Ver más</div>
              <div class="shadow"></div>
              <div class="box-txt">
                <h5>México limited, plataforma para apoyar a emprededores</h5>
                <h6>Hoy, hace 1 hora.</h6>

                <!-- <div class="descr">
                  <p>
                    Descripción breve del comunicado para pactar el interés del usuario.
                  </p>
                </div> -->
              </div>
            </div>
          </router-link>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      blog: [
        { imageUrl: 'public/images/pages/blog/article-1.jpg' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg' },
        { imageUrl: 'public/images/pages/blog/article-1.jpg' },
      ],
    }
  }
}
</script>
