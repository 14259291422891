<template lang="html">
  <div id="faqs-page">

    <section class="container oversized-container main-section">
      <div class="box-title-s2">
        <h1 class="title">PREGUNTAS FRECUENTES</h1>
      </div>

      <div class="row">
        <div class="col-lg-6 col-faq">
          <h6 class="q">¿Cuáles son las responsabilidades del mediador?</h6>
          <div class="box-answer">
            <p>
              El mediador, el Centro de Arbitraje Empresarial, y los representantes de éste, no serán responsables ante ninguna de las partes o ante sus representantes, por ningún acto u omisión relacionado directa o indirectamente con la mediación salvo por dolo patente, culpa grave, negligencia inexcusable o violación de confidencialidad conforme a lo previsto en el presente reglamento.
            </p>
          </div>

          <h6 class="mt-4 q">¿Como se comunican el mediador y las partes?</h6>
          <div class="box-answer">
            <p>
              1. El mediador podrá invitar a las partes a reunirse con él o comunicarse con ellas oralmente o por escrito. Podrá reunirse o comunicarse con las partes, ya sea conjuntamente o con cada una de ellas por separado.
            </p>
            <br />
            <p>
              2. A falta de acuerdo entre las partes respecto del lugar en que hayan de reunirse con el mediador, éste determinará el lugar, previa consulta con las partes, teniendo en consideración las circunstancias del procedimiento de mediación.
            </p>
          </div>

          <h6 class="mt-4 q">¿Cuanto es el numero de mediadores?</h6>
          <div class="box-answer">
            <p>
              En cualquier caso solo habrá un mediador, a menos que las partes acuerden que sean dos o tres los mediadores. Cuando haya más de un mediador deberán actuar conjuntamente.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-faq">
          <h6 class="q">¿Cuales son las funciones del medidor?</h6>

          <div class="box-answer">
            <p>
              1. El mediador ayudará a las partes de manera independiente e imparcial en sus esfuerzos por lograr un arreglo amistoso de la controversia.
            </p>
            <br />
            <p>
              2. El mediador se atendrá a principios de objetividad, equidad y justicia, teniendo en cuenta, entre otros factores, los derechos y las obligaciones de las partes, los usos del tráfico mercantil de que se trate y las circunstancias de la controversia, incluso cualesquiera prácticas establecidas entre las partes.
            </p>
            <br />
            <p>
              3. El mediador podrá conducir el procedimiento de mediación en la forma que estime adecuada, teniendo en cuenta las circunstancias del caso, los deseos que expresen las partes, incluida la solicitud de cualesquiera de ellas de que el mediador oiga declaraciones orales, y la necesidad de lograr un rápido arreglo de la controversia.
            </p>
            <br />
            <p>
              4. El mediador podrá, en cualquier etapa del procedimiento de mediación, formular propuestas para una transacción de la controversia. No es preciso que esas propuestas sean formuladas por escrito ni que se aplique el fundamento de ellas.
            </p>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
