<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title">Arancel de honorarios de arbitraje del CAE</h1>

    <p class="f-w-600 text-center">(pesos mexicanos)</p>
    <table class="mt-1 table table-sm text-center table-v-middle">
      <thead>
        <tr>
          <th colspan="4">MONTO EN LITIGIO (ML)</th>
          <th colspan="3">TASA ADMINISTRATIVA</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Hasta</td>
          <td>$ 250,000</td>
          <td></td>
          <td></td>

          <td>$ 10,000</td>
          <td class="more"></td>
          <td>Gastos</td>
        </tr>

        <tr>
          <td>De</td>
          <td>$ 250,001</td>
          <td>a</td>
          <td>$ 500,000</td>

          <td>$ 20,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 500,001</td>
          <td>a</td>
          <td>$ 750,000</td>

          <td>$ 30,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 750,001</td>
          <td>a</td>
          <td>$ 1,000,000</td>

          <td>$ 40,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 1,000,001</td>
          <td>a</td>
          <td>$ 1,500,000</td>

          <td>$ 50,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 1,500,001</td>
          <td>a</td>
          <td>$ 2,000,000</td>

          <td>$ 60,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 2,000,001</td>
          <td>a</td>
          <td>$ 5,000,000</td>

          <td>$ 80,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 5,000,001</td>
          <td>a</td>
          <td>$ 10,000,000</td>

          <td>$ 100,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 10,000,001</td>
          <td>a</td>
          <td>$ 40,000,000</td>

          <td>$ 150,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td>De</td>
          <td>$ 40,000,001</td>
          <td>a</td>
          <td>$ 80,000,000</td>

          <td>$ 200,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
        <tr>
          <td></td>
          <td>$ 80,000,001</td>
          <td></td>
          <td>ó más</td>

          <td>$ 300,000</td>
          <td class="more">+</td>
          <td>Gastos</td>
        </tr>
      </tbody>
    </table>

    <ol>
      <li>El anticipo sobre la tasa administrativa es del 50% de los honorarios establecidos en el arancel.</li>
      <li>Estos montos no incluyen impuesto al valor agregado (IVA)</li>
      <li>Se sugiere que en los asuntos asistidos por abogados certificados por el CAE el honorario no supere el 5% del ML</li>
    </ol>

    <br />
    <div class="row mx-0">
      <div class="col-lg-4 px-0">
        <h5 class="text-center">RIAL, SCP</h5>
        <h6 class="text-center">del arbitraje</h6>

        <table class="mt-2 table table-sm text-center">
          <thead>
            <tr>
              <th colspan="2">ÁRBITRO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 12,500</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 25,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 37,500</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 50,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 75,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 80,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 175,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 250,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 700,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Hasta $ 1,000,000</td>
            </tr>
            <tr>
              <td>5.00%</td>
              <td>Desde $ 1,000,000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <p>Los establecidos en el arancel.</p>
    <p>E el honorario no supere el 5% del ML</p>
  </div>
</template>

<script>
export default {
}
</script>
