<template lang="html">
  <div id="messages-page">

    <section class="container messages-section">
      <div class="row">
        <div class="col-12 col-title">
          <div class="box-title-s2">
            <h1 class="title">MIS MENSAJES</h1>
          </div>
        </div>

        <div class="col-12 col-main-content">
          <div class="box-inside">
            <div class="col-lg-4 col-cases">
              <div class="box" v-for="(c, mInx) in cases">
                <router-link class="btn-message" :to="'/mensajes/'+c.id">
                  <span class="case-id">Caso #{{ c.id }}</span>
                  <span class="case-title">Título del caso 1 bla bla</span>
                  <span class="vs">Vs</span>
                </router-link>
              </div>
            </div>

            <div class="col-lg-8 col-message">
              <div class="box-note" v-if="!$route.params.id">
                <i class="fad fa-comment-alt-lines icon"></i><br />
                <h5>Seleccione un caso para ver sus mensajes</h5>
              </div>

              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      cases: [
        { id: '21234' },
        { id: '21235' },
        { id: '21236' },
        { id: '21239' },
        { id: '21242' },
      ],
    }
  }
}
</script>
