<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section">
      <swiper class="" :options="bannersOption">
        <swiper-slide>
          <div class="swiper-el-con placed-backg" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/banner-1.jpg)' }">

            <div class="box-content">
              <div class="inside">
                <h2>ARBITRAJE</h2>
                <h4>SEGURO, CONFIABLE Y TRANSPARENTE</h4>

                <p>
                  <router-link class="t-250 _btn bg-white" to="/solicitud-proceso">INICIA UN PROCESO</router-link>
                  <router-link class="t-250 _btn bg-blue" to="/como-funciona">CÓMO FUNCIONA</router-link>
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>

      <!-- <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide v-if="banner.device == 'movil'" v-for="(banner, index) in banners" :key="index">
          <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" href="#">
            <img src="public/images/pages/home/banner-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper> -->
    </section>

    <section class="about-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-info">
            <div class="box-title-s1">
              <h2 class="title">SOBRE CAE</h2>
            </div>

            <div class="box-text">
              <p>
                Cuando las partes hayan acordado que las controversias entre ellas que deriven de o guarden relación con una determinada relación jurídica, contractual o no contractual, se sometan al arbitraje del CAE de conformidad con el Reglamento de Arbitraje del Centro de Arbitraje Empresarial.
              </p>
              <p class="mt-2">
                Este proceso será en linea, inmediato y mas rápido que un proceso tradicional.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-logo">
            <img src="public/images/pages/home/logo-cae.png">
          </div>
        </div>
      </div>
    </section>

    <section class="benefits-section">
      <div class="container oversized-container">
        <div class="row">

          <div class="col-12 col-title">
            <div class="box-title-s1">
              <h2 class="title">VENTAJAS</h2>
            </div>
          </div>

          <div class="col-sm-6 col-xl-3 col-box">
            <div class="inside">
              <div class="box-image">
                <img src="public/images/pages/home/icon-benefit-1.png">
              </div>

              <div class="box-text">
                <div>
                  <h6>Evita abogados</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-3 col-box">
            <div class="inside">
              <div class="box-image">
                <img src="public/images/pages/home/icon-benefit-2.png">
              </div>

              <div class="box-text">
                <div>
                  <h6>Proceso 100% válido ante la suprema corte</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-3 col-box">
            <div class="inside">
              <div class="box-image">
                <img src="public/images/pages/home/icon-benefit-3.png">
              </div>

              <div class="box-text">
                <div>
                  <h6>Proceso en línea</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-3 col-box">
            <div class="inside">
              <div class="box-image">
                <img src="public/images/pages/home/icon-benefit-4.png">
              </div>

              <div class="box-text">
                <div>
                  <h6>Sistema automatizado</h6>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">

          <div class="col-12 col-title">
            <div class="box-title-s1">
              <h2 class="title">COMUNICADOS</h2>
            </div>
          </div>

          <div class="col-12 col-articles">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(article, ahInx) in blog" :key="'ahInx-'+ahInx">
                <div class="col-12 px-0 article-sample-1">
                  <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" to="/comunicados/1">
                    <img class="empty" src="public/images/shared/empty.png">

                    <div class="inside">
                      <div class="t-250 show">Ver más</div>
                      <div class="shadow"></div>
                      <div class="box-txt">
                        <h5>Título de comunicado para hacer relleno aquí</h5>
                        <h6>Hoy, hace 1 hora.</h6>

                        <!-- <div class="descr">
                          <p>
                            Descripción breve del comunicado para pactar el interés del usuario.
                          </p>
                        </div> -->
                      </div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>


              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>

        </div>
      </div>
    </section>

    <section class="info-1-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-xl-9">
            <h2 class="section-title">CLÁUSULA MODELO DE ARBITRAJE EXPEDITO Y DE BAJA CUANTÍA</h2>

            <h5 class="mb-3">
              Copia esta cláusula arbitral en tus contratos y estarán sometidos al CAE.
            </h5>
            <p class="mb-3">
              Todas las controversias que deriven de o guarden relación con este contrato serán resueltas definitivamente de acuerdo con el Reglamento de Arbitraje Expedito y de Baja Cuantía del Centro de Arbitraje Empresarial, por un árbitro nombrado conforme a dicho Reglamento.
            </p>
            <p>
              Adicionalmente, las partes podrán estipular en la cláusula de arbitraje el derecho aplicable al fondo, el lugar del arbitraje y el idioma del procedimiento arbitral.
            </p>

            <p class="mt-4">
              <a class="btn" @click="copyThis">{{ txtCopy }}</a>
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      // banners: [
      //   { device: 'pc', imageUrl: 'public/images/pages/home/banner-1.jpg' },
      //   { device: 'movil', imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
      // ],

      blog: [
        { imageUrl: 'public/images/pages/blog/article-1.jpg' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg' },
      ],

      txtCopy: 'COPIAR',

      // == Carousel options ==
      bannersOption: {
        loop: false,
        speed:800,
        effect: 'fade',

        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },

      blogOptions: {
        slidesPerView: 4,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      // == ==
    }
  },

  methods: {
    copyThis(){
      let str = 'Todas las controversias que deriven de o guarden relación con este contrato serán resueltas definitivamente de acuerdo con el Reglamento de Arbitraje Expedito y de Baja Cuantía del Centro de Arbitraje Empresarial, por un árbitro nombrado conforme a dicho Reglamento. Adicionalmente, las partes podrán estipular en la cláusula de arbitraje el derecho aplicable al fondo, el lugar del arbitraje y el idioma del procedimiento arbitral.'
      this.txtCopy = '¡COPIADO!';
      setTimeout( ()=> { this.txtCopy = 'COPIAR'; }, 2000);

      if (navigator && navigator.clipboard && navigator.clipboard.writeText){
        return navigator.clipboard.writeText(str);
      }

    }
  }
}
</script>
