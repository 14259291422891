<template lang="html">
  <div id="how-page">

    <section class="container oversized-container main-section">
      <div class="box-title-s2">
        <h1 class="title">CÓMO FUNCIONA</h1>
      </div>

      <div class="row">
        <div class="col-12 col-desktop">
          <img src="public/images/pages/how/image-desktop.png">
        </div>

        <div class="col-12 col-mobile">
          <img class="img-sobre" src="public/images/pages/how/img-sobre.png">
        </div>

        <div class="col-md-6 col-mobile">
          <img src="public/images/pages/how/image-01.png">
        </div>

        <div class="col-md-6 col-mobile">
          <img src="public/images/pages/how/image-02.png">
        </div>

        <div class="col-md-6 col-mobile">
          <img src="public/images/pages/how/image-03.png">
        </div>

        <div class="col-md-6 col-mobile">
          <img src="public/images/pages/how/image-04.png">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
