<template lang="html">
  <header id="header">

    <div v-if="$route.meta.title == 'Iniciar sesión' && $route.query.id" class="header-top" style="background-color: rgb(247 165 70);height: 95px;text-align: center;">
    <p style="font-size: 25px; color: #022a56; padding: 16px;">{{$root.demanding}} te ha invitado a un proceso de {{$root.type}} , accede a tu cuenta con las credenciales enviadas por correo para continuar.</p>
    </div>

    <div class="header-top">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg">
          </b-navbar-brand>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item class="ml-lg-1 ml-xl-4 nav-button" to="/calculadora"><i class="far fa-calculator-alt"></i> Calculadora</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <li class="nav-item nav-network">
                <a class="nav-link" target="_blank" href="https://play.google.com/store/apps/details?id=com.cae.net&hl=es_MX">
                  <img src="public/images/shared/google-play.svg" alt="Google Play">
                </a>
              </li>

              <li class="nav-item nav-network">
                <a class="nav-link" target="_blank" href="https://apps.apple.com/mx/app/cae/id1659622089">
                  <img src="public/images/shared/app-store.svg" alt="App Store">
                </a>
              </li>

              <!-- Sin logear -->
              <!-- <b-nav-item class="simple-item" to="/registrarse" v-if="!$root.logged"><i class="fas fa-user-circle user-icon"></i> Crear una cuenta</b-nav-item> -->
              <li class="nav-item mx-1 simple-item" v-if="!$root.logged"><a class="nav-link">|</a></li>
              <b-nav-item class="simple-item" to="/login" v-if="!$root.logged"><i class="fal fa-user-circle user-icon"></i> Iniciar sesión</b-nav-item>
              <!--  -->

              <!-- Logeado -->
              <b-nav-item class="simple-item" to="/solicitud-proceso" v-if="$root.logged"><i class="fas fa-folder"></i> Iniciar un proceso</b-nav-item>
              <li class="nav-item mx-1 simple-item" v-if="$root.logged"><a class="nav-link">|</a></li>
              <b-nav-item class="simple-item" to="/casos" v-if="$root.logged"><i class="far fa-books"></i> Mis casos</b-nav-item>
              <!-- <li class="nav-item mx-1 simple-item" v-if="$root.logged"><a class="nav-link">|</a></li>
              <b-nav-item class="simple-item" to="/mensajes" v-if="$root.logged"><i class="far fa-bell"></i> Mis mensajes</b-nav-item> -->

              <li class="nav-item mx-1 simple-item" v-if="$root.logged"><a class="nav-link">|</a></li>
              <b-nav-item-dropdown class="simple-item" right v-if="$root.logged">
                <template #button-content>
                  <span><i class="fas fa-user-alt"></i> {{$root.user.name}} {{$root.user.lastname}}</span>
                </template>
                <b-dropdown-item to="/usuario">Mis datos</b-dropdown-item>
                <b-dropdown-item to="/usuario/contrasena">Cambiar contraseña</b-dropdown-item>
                <li class="no-hover">
                  <div class="dropdown-item">
                    <hr class="my-0" />
                  </div>
                </li>
                <b-dropdown-item to="/solicitud-proceso">Iniciar un proceso</b-dropdown-item>
                <b-dropdown-item to="/casos">Mis Casos</b-dropdown-item>
                <li class="nav-item" style="cursor: pointer;" @click="logout()">
                  <a class="dropdown-item">Cerrar sesión</a>
                </li>
                <!-- <b-dropdown-item to="/mensajes">Mis mensajes</b-dropdown-item> -->
              </b-nav-item-dropdown>
              <li class="nav-item mx-1 simple-item"><a class="nav-link">|</a></li>
              <b-nav-item class="simple-item" to="/afiliacion"><i class="fas fa-user-friends"></i> Afíliate al CAE</b-nav-item>
              <!--  -->

              <!-- <b-nav-item class="ml-2 special-item" to="/contacto">Contáctanos</b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <div class="header-bottom">
      <b-navbar toggleable="lg" type="dark" variant="black">
        <div class="container oversized-container">
          <b-navbar-brand class="d-lg-none" to="/">
            <img src="public/images/logo-white.svg">
          </b-navbar-brand>

          <div class="box-network">
            <a class="btn-network" target="_blank" href="https://play.google.com/store/apps/details?id=com.cae.net&hl=es_MX">
              <img class="img" src="public/images/shared/google-play.svg" alt="Google Play">
              <span class="text">
                <i class="fab fa-google-play"></i>
              </span>
            </a>

            <a class="btn-network" target="_blank" href="https://apps.apple.com/mx/app/cae/id1659622089">
              <img class="img" src="public/images/shared/app-store.svg" alt="App Store">
              <span class="text">
                <i class="fab fa-app-store-ios"></i>
              </span>
            </a>
          </div>

          <b-navbar-toggle target="nav-collapse"><i class="far fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item class="simple-item" to="/">Inicio</b-nav-item>
              <b-nav-item class="simple-item" to="/como-funciona">Cómo funciona</b-nav-item>
              <b-nav-item class="simple-item" to="/preguntas-frecuentes">Preguntas frecuentes</b-nav-item>
              <b-nav-item class="simple-item" to="/servicios">Servicios</b-nav-item>
              <b-nav-item class="simple-item" to="/sobre-cae">Sobre CAE</b-nav-item>
              <b-nav-item class="simple-item" to="/comunicados">Comunicados</b-nav-item>
              <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/calculadora"><i class="far fa-calculator-alt"></i> Calculadora</b-nav-item>

              <b-nav-item-dropdown class="simple-item d-lg-none" right v-if="!$root.logged">
                <template #button-content>
                  <span><i class="far fa-user"></i> Mi cuenta</span>
                </template>
                <b-dropdown-item to="/login">Iniciar sesión</b-dropdown-item>
                <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item d-lg-none" to="/solicitud-proceso"><i class="fas fa-folder"></i> Iniciar un proceso</b-nav-item>

              <b-nav-item-dropdown class="simple-item d-lg-none" right v-if="$root.logged">
                <template #button-content>
                  <span><i class="fas fa-user-alt"></i> Mi cuenta</span>
                </template>
                <b-dropdown-item to="/usuario">Mis datos</b-dropdown-item>
                <b-dropdown-item to="/usuario/contrasena">Cambiar contraseña</b-dropdown-item>
                <li class="no-hover">
                  <div class="dropdown-item">
                    <hr class="my-0" />
                  </div>
                </li>
                <b-dropdown-item to="/solicitud-proceso">Iniciar un proceso</b-dropdown-item>
                <b-dropdown-item to="/casos">Mis Casos</b-dropdown-item>
                <b-dropdown-item to="/mensajes">Mis mensajes</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item d-lg-none" to="/afiliacion"><i class="fas fa-user-friends"></i> Afíliate al CAE</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

  </header>
</template>

<script>
export default {
  data(){
    return{
    }
  },

  methods: {
    logout(){
        axios.post(tools.url("/api/logout")).then((response)=>{
            this.$parent.user = {};
            this.$parent.logged = false;
            this.$router.push('/login');
        }).catch(()=>{});
    },
  },
}
</script>
