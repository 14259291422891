<template lang="html">
  <div id="case-info-page">

    <section class="container oversized-container top-section">
      <div class="row align-items-center">
        <div class="col-lg-7 col-left">
          <h6 class="txt-breadcrumb">ID CAE000{{row.id}}</h6>
          <h4 class="case-title">{{row.demanding_data.name}} {{row.demanding_data.lastname}} <span v-if="row.demanding_data.company_name">- {{row.demanding_data.company_name}}</span> VS {{row.accused_data.name}} {{row.accused_data.lastname}} <span v-if="row.accused_data.company_name">- {{row.accused_data.company_name}}</span> </h4>
          <h5 class="case-id">{{row.typecustom}}</h5>
          <h5 class="case-id" v-if="$root.user.id == row.accused_id">DEMANDADO</h5>
          <h5 class="case-id" v-if="$root.user.id != row.accused_id">DEMANDANTE</h5>
        </div>

        <div class="col-lg-5 col-right">
          <div class="box-notifications-messages">
            <div class="box-icon" @click="openModalPush">
              <i class="far fa-bell icon"></i>
              <i class="num">{{row.notificationsnoreaded}}</i>
            </div>

            <div class="box-icon msg" @click="$refs['modal-messages'].show()">
              <i class="far fa-envelope icon"></i>
              <!-- <i class="num">21</i> -->
            </div>
          </div>

          <div class="box-arbitro" v-for="(refe,indx) in row.referees" :key="indx">
            <div class="col col-text">
              <h6>{{refe.typeuser}}</h6>
              <h5>{{refe.name}} {{refe.lastname}}</h5>
            </div>

            <div class="col col-photo">
              <div class="placed-backg box-photo" v-bind:style="{ backgroundImage: 'url('+refe.imageUrl+')' }"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container content-section">
      <div class="row">
        <div class="col-lg-12 col-historical">
          <div class="row box-basic-info">
            <div class="col-lg-3 col-i">
              <span class="lab">FECHA DE INICIO: </span>
              <span class="txt">{{row.created}}</span>
              <br><br>
              <span class="lab">FECHA DE ULTIMA ACTUALIZACION: </span>
              <span class="txt">{{row.updated}}</span>
            </div>

            <div class="col-lg-3 col-i">
              <span class="lab">MONTO RECLAMADO: </span>
              <span class="txt">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row.amount) }}</span>
            </div>

            <div class="col-lg-6 col-i">
              <span class="lab">ESTATUS: </span>
              <div class="box-status">
                <span class="status" v-bind:class="{ active: row.active1 }" @click="activePart(1)">Información</span>
                <span class="status" v-bind:class="{ active: row.active2 }" @click="activePart(2)">Demanda</span>
                <span class="status" v-bind:class="{ active: row.active3 }" @click="activePart(3)">Contestacion</span>
                <span class="status" v-bind:class="{ active: row.active4 }" @click="activePart(4)">Revocación</span>
                <span class="status" v-bind:class="{ active: row.active5 }" @click="activePart(5)">Conferencia de audiencia</span>
                <span class="status" v-bind:class="{ active: row.active6 }" @click="activePart(6)">Deshaogo de pruebas</span>
                <span class="status" v-bind:class="{ active: row.active7 }" @click="activePart(7)">Cierre</span>
                <span class="status" v-bind:class="{ active: row.active8 }" @click="activePart(8)">Laudo</span>
              </div>
            </div>
          </div>

          <div class="row box-historical" v-if="active == 1">
            <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
              <h5>Información</h5>
            </div>

            <div class="col-12 col-move">
                <div class="row">
                  <b-form-group label="Tipo" class="col-sm-6">
                      <b-form-input type="text" v-model="row.typecustom" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group label="Monto demandado" class="col-sm-6">

                      <currency-input id="i-co" class="form-control" v-model="row.amount" disabled /> 

                  </b-form-group>

                  <b-form-group label="Demandante" class="col-sm-6">
                      <b-form-input type="text" v-model="row.demanding_data.name_lastname" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group label="Acusado" class="col-sm-6">
                      <b-form-input type="text" v-model="row.accused_data.name_lastname" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group :label="row.typecustom_refere" class="col-sm-6">
                      <b-form-input type="text" v-model="row.refereesname" disabled></b-form-input>
                  </b-form-group>
                   <b-form-group label="Perito" class="col-sm-6" v-if="row.perito_data.name">
                      <b-form-input type="text" v-model="row.perito_data.name_lastname" disabled></b-form-input>
                  </b-form-group>
                  
                   


                </div>
              <!-- <a class="box" @click="openModal(h)">
                <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h6 class="title">{{ h.title }}</h6>
                </div>


                <div class="col col-txt">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>

                <div class="col col-date">
                  <p>FECHA {{ h.date }}</p>
                </div>
              </a> -->
            </div>
          </div>

          <div class="row box-historical" v-if="active == 2">
              <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Demanda</h5>
              </div>
              <div class="col-12 col-move">
                  <div class="row">
                      <b-form-group label="Descripcion de la demanda" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.litigation_points" disabled style="height: 500px;"></b-form-textarea>
                      </b-form-group>
                  
                      <!-- <b-form-group label="Contenido de la demanda" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.content_demand" disabled></b-form-textarea>
                      </b-form-group>
 -->
                      <div class="col-sm-12">
                        <!-- <p>Archivos</p>
                        <div style="padding-right: 10px;display: inline;" v-for="(doc,indx) in row.pdfs_demand" :key="indx">
                            <a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a>
                        </div> -->

                        <p>Archivos</p>
                        <table>
                          <tr>
                            <th>Nombre de archivo</th>
                            <th>Fecha hora de publicacion</th>
                            <th>Descargar</th>
                            <!-- <th>Eliminar</th> -->
                          </tr>
                          <tr v-for="(doc,indx) in row.pdfs_demand" :key="indx">
                              <td>{{doc.name_document}}</td>
                              <td>{{doc.created}}</td>
                              <td><a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a></td>
                              <!-- <td><a v-if="doc.users_id == $root.user.id" class="btn btn-danger" @click="deleteDoc(doc.id)"><i class="fas fa-trash"></i></a></td>
                             -->
                        </tr>
                        </table>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row box-historical" v-if="active == 3">
              <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Contestacion</h5>
              </div>
              <div class="col-12 col-move">
                  <div class="row">
                      <b-form-group label="Fecha limite" class="col-sm-6">
                        <b-form-input type="date" v-model="row.deadline" disabled></b-form-input>
                    </b-form-group>

                      <b-form-group v-if="$root.user.id == row.accused_id" label="Contenido de la contestación" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.content_reply"  style="height: 500px;"></b-form-textarea>
                      </b-form-group>

                      <b-form-group v-else disabled label="Contenido de la contestación" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.content_reply" disabled style="height: 500px;"></b-form-textarea>
                      </b-form-group>

                      <div class="col-sm-12">
                        <!-- <p>Archivos</p>
                        <div style="padding-right: 10px;display: inline;" v-for="(doc,indx) in row.pdfs_reply" :key="indx">
                            <a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a>
                        </div> -->
                        

                        <p>Subir nuevo archivo</p>
                        <vue-dropzone ref="VueDropzoneReply" id="dropzone" :options="dropzoneOptionsReply" @vdropzone-complete="onUploadReply" :useCustomSlot=true>
                            <div class="dropzone-custom-content">
                              <div class="subtitle">Agrega aqui archivos de cualquier tipo</div>
                            </div>
                          </vue-dropzone>
                        <br><br>

                        <p>Archivos</p>
                        <table>
                          <tr>
                            <th>Nombre de archivo</th>
                            <th>Fecha hora de publicacion</th>
                            <th>Descargar</th>
                           <th>Eliminar</th>
                          </tr>
                          <tr v-for="(doc,indx) in row.pdfs_reply" :key="indx">
                              <td>{{doc.name_document}}</td>
                              <td>{{doc.created}}</td>
                              <td><a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a></td>
                              <td><a v-if="doc.users_id == $root.user.id" class="btn btn-danger" @click="deleteDoc(doc.id)"><i class="fas fa-trash"></i></a></td>
                             
                        </tr>
                        </table>
                      </div>


                      <br><br><br><br>
                      <div class="col-sm-12" v-if="$root.user.id == row.accused_id">
                          <b-button style="background-color: #022a56;border-color: #022a56;" @click="updateRow" class="btn btn-blue">Guardar</b-button>
                      </div>

                  </div>
              </div>
          </div>

          <div class="row box-historical" v-if="active == 4">
              <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Revocación</h5>
              </div>
              <div class="col-12 col-move">
                  <div class="row">
                   <b-form-group label="Revocación" class="col-sm-12">
                        <b-form-textarea type="text" v-model="row.revocation" disabled></b-form-textarea>
                    </b-form-group>
                  </div>
              </div>
          </div>

          <div class="row box-historical" v-if="active == 5">
              <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Conferencia</h5>
              </div>
              <div class="col-12 col-move">
                  <div class="row">
                     <b-form-group label="Fecha y hora" class="col-sm-6">
                          <b-form-input type="datetime-local" v-model="row.date_hour_audience" disabled></b-form-input>
                      </b-form-group>
                      
                      <b-form-group label="Link" class="col-sm-6">
                          <b-form-input v-model="row.link_audience" disabled></b-form-input>
                      </b-form-group>
                   

               
                      <b-form-group label="Comentarios" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.comments_audience" disabled></b-form-textarea>
                      </b-form-group>

                      <b-form-checkbox v-if="$root.user.id == row.demanding_id" style="padding-left: 45px;" v-model="row.confirm_demanding_id_audience" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.demanding_data.name}} {{row.demanding_data.lastname}}
                      </b-form-checkbox>
                      <b-form-checkbox v-else disabled style="padding-left: 45px;" v-model="row.confirm_demanding_id_audience" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.demanding_data.name}} {{row.demanding_data.lastname}}
                      </b-form-checkbox>

                      <b-form-checkbox v-if="$root.user.id == row.accused_id" style="padding-left: 45px;" v-model="row.confirm_accused_id_audience" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.accused_data.name}} {{row.accused_data.lastname}}
                      </b-form-checkbox>
                      <b-form-checkbox v-else disabled style="padding-left: 45px;" v-model="row.confirm_accused_id_audience" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.accused_data.name}} {{row.accused_data.lastname}}
                      </b-form-checkbox>

                      <b-form-checkbox disabled style="padding-left: 45px;" v-model="row.confirm_referees_id_audience" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.refereesname}}
                      </b-form-checkbox>

                      <br><br>
                      <div class="col-sm-12">
                          <b-button style="background-color: #022a56;border-color: #022a56;" @click="updateRow" class="btn btn-blue">Confirmar</b-button>
                      </div>

                  </div>

              </div>
          </div>

          <div class="row box-historical" v-if="active == 6">
              <!-- <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Audiencia</h5>
              </div>
              <div class="col-12 col-move">
                 <div class="row">
                     <b-form-group label="Fecha y hora" class="col-sm-6">
                          <b-form-input type="datetime-local" v-model="row.date_hour_conference" disabled></b-form-input>
                      </b-form-group>
                      
                      <b-form-group label="Link" class="col-sm-6">
                          <b-form-input v-model="row.link_conference" disabled></b-form-input>
                      </b-form-group>
                   

               
                      <b-form-group label="Comentarios" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.comments_conference" disabled></b-form-textarea>
                      </b-form-group>

                      <b-form-checkbox v-if="$root.user.id == row.demanding_id" style="padding-left: 45px;" v-model="row.confirm_demanding_id_conference" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.demanding_data.name}}
                      </b-form-checkbox>
                      <b-form-checkbox v-else disabled style="padding-left: 45px;" v-model="row.confirm_demanding_id_conference" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.demanding_data.name}}
                      </b-form-checkbox>

                      <b-form-checkbox v-if="$root.user.id == row.accused_id" style="padding-left: 45px;" v-model="row.confirm_accused_id_conference" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.accused_data.name}}
                      </b-form-checkbox>
                      <b-form-checkbox v-else disabled style="padding-left: 45px;" v-model="row.confirm_accused_id_conference" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.accused_data.name}}
                      </b-form-checkbox>


                      <b-form-checkbox disabled style="padding-left: 45px;" v-model="row.confirm_referees_id_conference" name="usertype" :value="1" :unchecked-value="null" >
                           Confirma - {{row.refereesname}}
                      </b-form-checkbox>
                      <br><br>
                      <div class="col-sm-12">
                          <b-button style="background-color: #022a56;border-color: #022a56;" @click="updateRow" class="btn btn-blue">Confirmar</b-button>
                      </div>
                      

                  </div>
              </div> -->

              <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Deshaogo de pruebas</h5>
              </div>
              <div class="col-12 col-move">
                  <div class="row">
                      <b-form-group label="Deshaogo de pruebas" class="col-sm-12" v-if="$root.user.id == row.demanding_id">
                          <b-form-textarea type="text" v-model="row.evidence_demanding" style="height: 500px;"></b-form-textarea>
                      </b-form-group>

                      <b-form-group :label="'Deshaogo de pruebas '+row.demanding_data.name_lastname" class="col-sm-12" v-else>
                          <b-form-textarea type="text" v-model="row.evidence_demanding" style="height: 500px;" disabled></b-form-textarea>
                      </b-form-group>

                      <b-form-group label="Deshaogo de pruebas" class="col-sm-12" v-if="$root.user.id == row.accused_id">
                          <b-form-textarea type="text" v-model="row.evidence_accused" style="height: 500px;"></b-form-textarea>
                      </b-form-group>
                      <b-form-group :label="'Deshaogo de pruebas '+row.accused_data.name_lastname" class="col-sm-12" v-else>
                          <b-form-textarea type="text" v-model="row.evidence_accused" style="height: 500px;" disabled></b-form-textarea>
                      </b-form-group>
                  
                      <!-- <b-form-group label="Contenido de la demanda" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.content_demand" disabled></b-form-textarea>
                      </b-form-group>
 -->
                      <div class="col-sm-12">
                        

                        <p>Subir nuevo archivo</p>
                        <vue-dropzone ref="VueDropzoneEvidence" id="dropzone" :options="dropzoneOptionsEvidence" @vdropzone-complete="onUploadEvidence" :useCustomSlot=true><div class="dropzone-custom-content">
                              <div class="subtitle">Agrega aqui archivos de cualquier tipo</div>
                            </div></vue-dropzone>
                        <br><br>

                        <p>Archivos</p>
                        <table>
                          <tr>
                            <th>Nombre de archivo</th>
                            <th>Fecha hora de publicacion</th>
                            <th>Descargar</th>
                            <th>Eliminar</th>
                          </tr>
                          <tr v-for="(doc,indx) in row.pdfs_evidence" :key="indx">
                              <td>{{doc.name_document}}</td>
                              <td>{{doc.created}}</td>
                              <td><a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a></td>
                              <td><a v-if="doc.users_id == $root.user.id" class="btn btn-danger" @click="deleteDoc(doc.id)"><i class="fas fa-trash"></i></a></td>
                            
                        </tr>
                        </table>
                        

                      </div>

                      <br><br><br><br>
                      <div class="col-sm-12">
                          <b-button style="background-color: #022a56;border-color: #022a56;" @click="updateRow" class="btn btn-blue">Guardar</b-button>
                      </div>

                
                  </div>
              </div>
          </div>

          <div class="row box-historical" v-if="active == 7">
              <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Cierre</h5>
              </div>
              <div class="col-12 col-move">

                  <div class="row">
                      <b-form-group label="Cierre" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.closing" disabled></b-form-textarea>
                      </b-form-group>

                      <div class="col-sm-12">

                        <p>Archivos</p>
                        <table>
                          <tr>
                            <th>Nombre de archivo</th>
                            <th>Fecha hora de publicacion</th>
                            <th>Descargar</th>
                            <!-- <th>Eliminar</th> -->
                          </tr>
                          <tr v-for="(doc,indx) in row.pdfs_closing" :key="indx">
                              <td>{{doc.name_document}}</td>
                              <td>{{doc.created}}</td>
                              <td><a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a></td>
                              <!-- <td><a v-if="doc.users_id == $root.user.id" class="btn btn-danger" @click="deleteDoc(doc.id)"><i class="fas fa-trash"></i></a></td>
                             -->
                        </tr>
                        </table>

                      </div>
                  </div>
              </div>
          </div>

          <div class="row box-historical" v-if="active == 8">
              <div class="col-12 col-title" style="background-color:#022a56;color:white;text-align: center;">
                  <h5 style="text-align:center;">Laudo</h5>
              </div>
              <div class="col-12 col-move">
                <div class="row">

                      <b-form-group class="col-lg-12 custom-group-s1 c-radios-group-s1" label="Se falla a favor de" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          class="radiosdos"
                          v-model="row.ruled_favor"
                          :aria-describedby="ariaDescribedby"
                          
                          disabled
                          name="radio-sub-component">
                          <b-form-radio class="col-lg-4" :value="row.demanding_id">{{row.demanding_data.name}}</b-form-radio>
                          <b-form-radio class="col-lg-4 my-1 my-lg-0" :value="row.accused_id">{{row.accused_data.name}}</b-form-radio>
                         
                        </b-form-radio-group>


                      </b-form-group>

                      <b-form-group label="Comentarios" class="col-sm-12">
                          <b-form-textarea type="text" v-model="row.comments" disabled></b-form-textarea>
                      </b-form-group>

                      <div class="col-sm-12">
                        <!-- <p>Archivos</p>
                        <div style="padding-right: 10px;display: inline;" v-for="(doc,indx) in row.pdfs_grant" :key="indx">
                            <a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a>
                        </div> -->

                        <p>Archivos</p>
                        <table>
                          <tr>
                            <th>Nombre de archivo</th>
                            <th>Fecha hora de publicacion</th>
                            <th>Descargar</th>
                            <!-- <th>Eliminar</th> -->
                          </tr>
                          <tr v-for="(doc,indx) in row.pdfs_grant" :key="indx">
                              <td>{{doc.name_document}}</td>
                              <td>{{doc.created}}</td>
                              <td><a style="background-color:#022a56;border-color:#022a56" class="btn btn-info"  :href="doc.documentUrl" target="_blank"><i class="fas fa-cloud-download-alt"></i></a></td>
                              <!-- <td><a v-if="doc.users_id == $root.user.id" class="btn btn-danger" @click="deleteDoc(doc.id)"><i class="fas fa-trash"></i></a></td>
                             -->
                        </tr>
                        </table>
                      </div>
                  </div>
              </div>
          </div>


        </div>

      </div>
    </section>

    <!-- Modal "Historico" -->
    <b-modal modal-class="modal-historical-s1" ref="modal-show-info" size="xl" title="" hide-footer centered scrollable >
      <h5 class="modal-title">{{ modalObj.title }}</h5>

      <h6 class="modal-date">FECHA {{ modalObj.date }}</h6>

      <div class="box-info">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
    </b-modal>
    <!--  -->

    <!-- Modal notificaciones -->
    <b-modal modal-class="modal-notifications-s1" ref="modal-notifications" title="" hide-footer centered no-close-on-esc no-close-on-backdrop>
      <h5 class="modal-title"><i class="far fa-bell mr-1"></i> NOTIFICACIONES ({{row.notifications}})</h5>

      <div class="box-notifications">
        <a class="box-notification"  v-for="(n, nInx) in row.notifications" :key="'nInx-'+nInx">
        <!-- <a class="box-notification" @click="openModal(n)" v-for="(n, nInx) in row.notifications" :key="'nInx-'+nInx"> -->
          <h6 class="title">Actualizacion de información</h6>

          <!-- <div class="box-txt">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div> -->
        </a>
      </div>
    </b-modal>
    <!--  -->

    <!-- Modal Mensajes -->
    <b-modal modal-class="modal-message-s1" ref="modal-messages" size="lg" title="" hide-footer centered no-close-on-esc no-close-on-backdrop>
      <h5 class="text-center modal-title">MENSAJES</h5>

      <messages-component :caseid="$route.params.id"></messages-component>
    </b-modal>
    <!--  -->
    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">Aceptar</b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
import messagesComponent from '../messages/messages-component.vue'
export default {
  components: {
    messagesComponent
  },

  data(){
    return{
      modalObj: {},

      notifications: [
        { title: 'Título de la notificación 1', date: '18/04/2022' },
        { title: 'Título de la notificación 2', date: '18/04/2022' },
      ],

      historico: [
        { title: 'TÍTULO DE MOVIMIENTO 1', date: '21/03/2022' },
        { title: 'TÍTULO DE MOVIMIENTO 2', date: '21/03/2022' },
      ],

      row:{
          demanding_data:{},
          accused_data:{},
          perito_data:{}
      },
      active:1,
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      dropzoneOptionsReply: {
        url: tools.url('/api/dropzone/casereply/' + this.$route.params.id),
        acceptedFiles:'',
        uploadMultiple:false,
        maxFilesize:3,
        withCredentials:false,
        
      },
      dropzoneOptionsEvidence: {
        url: tools.url('/api/dropzone/caseevidence/' + this.$route.params.id),
        acceptedFiles:'',
        uploadMultiple:false,
        maxFilesize:3,
        withCredentials:false,
        
      },
      
    }
  },

  methods: {
    openModal(obj){
      this.modalObj = obj;
      this.$refs['modal-show-info'].show();
    },
    getRow(){
        axios.get(tools.url("/api/caseesdetail/"+this.$root.user.id+"/"+this.$route.params.id)).then((response)=>{
            this.row = response.data;
        }).
        catch(()=>{
        });
    },
    updateRow(){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Guardando información. por favor espere...';
        this.$refs.modal.open();
        
        var dataform = {
            'confirm_demanding_id_audience':this.row.confirm_demanding_id_audience,
            'confirm_accused_id_audience':this.row.confirm_accused_id_audience,

            'confirm_demanding_id_conference':this.row.confirm_demanding_id_conference,
            'confirm_accused_id_conference':this.row.confirm_accused_id_conference,

            'content_reply':this.row.content_reply,

            'evidence_demanding':this.row.evidence_demanding,
            'evidence_accused':this.row.evidence_accused,
            'users_id':this.$root.user.id
        };

        axios.post(tools.url("/api/casees/"+this.$route.params.id),dataform).then((response)=>{
            this.row = response.data;

            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "Información guardada correctamente";
            this.modal.block = false;
            
            this.getRow();

        }).
        catch(()=>{
          this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = response.data.message;
            this.modal.block = false;
        });
    },
    openModalPush(){
        this.$refs['modal-notifications'].show();
        axios.get(tools.url("/api/caseesnotifications/"+this.$root.user.id+"/"+this.$route.params.id)).then((response)=>{
            this.getRow();
        }).
        catch(()=>{
        });
    },
    activePart(num){
        if (num == 1) {
            if (this.row.active1 == 1) {
                this.active = 1;
            }
        }
        else if(num == 2) {
            if (this.row.active2 == 1) {
                this.active = 2;
            }
        }
        else if(num == 3) {
            if (this.row.active3 == 1) {
                this.active = 3;
            }
        }
        else if(num == 4) {
            if (this.row.active4 == 1) {
                this.active = 4;
            }
        }
        else if(num == 5) {
            if (this.row.active5 == 1) {
                this.active = 5;
            }
        }
        else if(num == 6) {
            if (this.row.active6 == 1) {
                this.active = 6;
            }
        }
        else if(num == 7) {
            if (this.row.active7 == 1) {
                this.active = 7;
            }
        }
        else if(num == 8) {
            if (this.row.active8 == 1) {
                this.active = 8;
            }
        }

    },
    onUploadReply(file, response) {
        setTimeout(() => {
              let vueDropzone = this.$refs['VueDropzoneReply'];
          vueDropzone.removeFile(file);
          this.getRow();
        }, 1000);

      },
      onUploadEvidence(file, response) {
        setTimeout(() => {
              let vueDropzone = this.$refs['VueDropzoneEvidence'];
          vueDropzone.removeFile(file);
          this.getRow();
        }, 1000);

      },
      deleteDoc(id){
        let text = "¿Seguro que deseas borrar este archivo?";
        if (confirm(text) == true) {
            axios.delete(tools.url("/api/dropzone/deletedocument/"+id)).then((response)=>{
                this.getRow();
            }).
            catch(()=>{
            });
        }
          
      }
  },

  mounted(){
      this.getRow();
  }
}
</script>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #dddddd;
}
</style>