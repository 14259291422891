<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/background.jpg)' }"></div>

      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-info">
            <div class="box-title-s2">
              <h1 class="title">SOBRE CAE</h1>
            </div>

            <p class="text-center mt-2 mb-4">
              <img src="public/images/pages/about-us/logo.png">
            </p>

            <div class="box-text">
              <p>
                El Centro de Arbitraje Empresarial es una institución privada cuyo objeto es prestar el servicio de administración de arbitrajes y otros mecanismos alternativos de solución de controversias de conformidad con lo dispuesto por este Reglamento.
              </p>
              <br />
              <p>
                El CAE no resuelve por sí mismo las controversias que le son planteadas. Su misión es hacer cumplir el presente Reglamento y cualquier otro Reglamento que emita el Consejo General.
              </p>
              <br />
              <p>
                El CAE ejerce sus funciones a través de uno o más administradores del caso, nombrados por el Consejo General.
              </p>
              <br />
              <p>
                Cuando las partes hayan acordado que las controversias entre ellas que deriven de o guarden relación con una determinada relación jurídica, contractual o no contractual, se sometan al arbitraje del CAE de conformidad con el Reglamento de Arbitraje del Centro de Arbitraje Empresarial, dichas controversias se resolverán de conformidad con el Reglamento de Arbitraje del CAE vigente en la fecha de inicio del procedimiento, a menos que las partes acuerden someterse al Reglamento de Arbitraje del CAE vigente a la fecha del acuerdo de arbitraje.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
