<template lang="html">
    <div id="user-access-page" class="placed-backg">
  
      <div class="container">
        <b-form class="row mx-0 flex-center-xy pt-5 page-size">
  
          <div class="form-container pt-5 w-100">
          
  
            <div class="form">
            

              <div>
                <div class="d-block mb-3 text-center">
                  <h5 class="f-w-600 txt-blue">Calculador automático a partir del 1º de octubre de 2011</h5>
                </div>
  

                <hr class="mt-1 hr-black" />
  
                <div class="row pt-1">
                    <div class="col-lg-3 custom-group-s1"></div>
                  <b-form-group class="col-lg-6 custom-group-s1" label="Monto del Litigio">
                    <!-- <b-form-input type="text" v-model="form.amount" required placeholder=""></b-form-input> -->
                   <currency-input id="i-co" class="form-control" v-model="form.amount" placeholder="$0.00" required min="1" /> 
  
                  </b-form-group>
                  <div class="col-lg-3 custom-group-s1"></div>

                  <div class="col-lg-3 custom-group-s1"></div>
                  <b-form-group class="col-lg-6 custom-group-s1" label="Comision a pagar al C.A.E. (5% del monto demandado)">
                   <currency-input id="i-co" class="form-control" v-model="form.commission_cae" placeholder="$0.00" required min="1" disabled/> 
                  </b-form-group>
                  <div class="col-lg-3 custom-group-s1"></div>

                  <div class="col-lg-3 custom-group-s1"></div>
                  <b-form-group class="col-lg-6 custom-group-s1" label="Tasa Administrativa">
                   <currency-input id="i-co" class="form-control" v-model="form.administrative_fee" placeholder="$0.00" required min="1" disabled/> 
                  </b-form-group>
                  <div class="col-lg-3 custom-group-s1"></div>


                  <div class="col-lg-3 custom-group-s1"></div>
                  <b-form-group class="col-lg-6 custom-group-s1" label="Total a pagar por iniciacion de proceso al C.A.E. posteriormente pueden ser rembolsados">
                   <currency-input style="background-color: #38c538; font-weight: 700;" id="i-co" class="form-control" v-model="form.total" placeholder="$0.00" required min="1" disabled/> 
                  </b-form-group>
                  <div class="col-lg-3 custom-group-s1"></div>

                  <div class="col-lg-12 custom-group-s1">
                    Les recordamos que de acuerdo con el artículo 38(1) de las Reglas de Arbitraje del CAM, corresponde al Secretario General fijar el importe del depósito de fondos para cubrir los gastos de arbitraje utilizando el Arancel para el cálculo de los gastos del arbitraje que establece el Apéndice II. El Secretario General fijará el importe de dicho depósito a su discreción si el monto en litigio fuere indeterminado.Asimismo, conforme a lo establecido en el artículo 38(2) de las Reglas, el monto del depósito fijado por el Secretario General para cubrir los gastos del arbitraje podrá ser revisado y ajustado para tomar en consideración modificaciones ulteriores del monto en litigio, cambios en la estimación de gastos del Tribunal Arbitral y la dificultad o complejidad del procedimiento.

                  </div>

                  
                </div>
  
                </div>
              </div>

            </div>
  
       
        </b-form>
      </div>
  
    
  
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{

  
        form: {
          amount:null,
          commission_cae:0,
          administrative_fee:0,
          total:0,

        },
  
      
      }
    },
    watch:{
      
      'form.amount':function(val){
          this.form.commission_cae = this.form.amount * .05;
          if(this.form.amount <= 250000){
              this.form.administrative_fee = 10000;
          }
          else if(this.form.amount > 250000 || this.form.amount <= 500000){
              this.form.administrative_fee = 20000;
          }
          else if(this.form.amount > 500000 || this.form.amount <= 750000){
              this.form.administrative_fee = 30000;
          }
          else if(this.form.amount > 750000 || this.form.amount <= 1000000){
              this.form.administrative_fee = 40000;
          }
          else if(this.form.amount > 1000000 || this.form.amount <= 1500000){
              this.form.administrative_fee = 50000;
          }
          else if(this.form.amount > 1500000 || this.form.amount <= 2000000){
              this.form.administrative_fee = 60000;
          }
          else if(this.form.amount > 2000000 || this.form.amount <= 5000000){
              this.form.administrative_fee = 80000;
          }
          else if(this.form.amount > 5000000 || this.form.amount <= 10000000){
              this.form.administrative_fee = 100000;
          }
          else if(this.form.amount > 10000000 || this.form.amount <= 40000000){
              this.form.administrative_fee = 150000;
          }
          else if(this.form.amount > 40000000 || this.form.amount <= 80000000){
              this.form.administrative_fee = 200000;
          }
          else if(this.form.amount > 80000000){
              this.form.administrative_fee = 300000;
          }
  
          this.form.total = this.form.commission_cae + this.form.administrative_fee;
      },
    },
  
    methods: {
      
    },
  
    mounted(){
      
    }
  }
  </script>
  