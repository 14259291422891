<template lang="html">
  <div id="contact-page">

    <section class="container form-section">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="box-form">
            <h1 class="title">Llena el formulario para contactarnos</h1>

            <b-form @submit="onSubmit">
              <b-form-group>
                <b-form-input
                  v-model="form.email"
                  type="email"
                  placeholder="CORREO ELECTRÓNICO"
                  required
                ></b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-md-8 pr-md-0">
                  <b-form-group>
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      placeholder="NOMBRE COMPLETO"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-4">
                  <b-form-group>
                    <b-form-input
                      v-model="form.phone"
                      type="text"
                      placeholder="TELÉFONO"
                      minlength="10"
                      maxlength="10"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-6 pr-md-2">
                  <b-form-group>
                    <b-form-input
                      v-model="form.company"
                      type="text"
                      placeholder="EMPRESA"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-6 pl-md-2">
                  <b-form-group>
                    <b-form-input
                      v-model="form.state"
                      type="text"
                      placeholder="ESTADO"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-12">
                  <b-form-group>
                    <b-form-textarea
                      v-model="form.msg"
                      placeholder="ESCRIBE TU MENSAJE"
                      rows="4"
                      max-rows="4"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="col-12 text-center">
                  <b-button type="submit" class="t-400 btn-send">CONFIRMAR Y ENVIAR</b-button>
                </div>
              </div>

            </b-form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null,
        name: null,
        phone: null,
        company: null,
        state: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post(apiURL,
        params
      )
      .then( (response)=> {
        // alert(response.data.response);
        // this.makeToast('success', response.data.response, 'Message sent');
        alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.company, 'Error');
        this.makeToast('danger', error.response.data.state, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    }
  },
}
</script>
