<template lang="html">
  <div id="services-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12">
          <div class="box-title-s2">
            <h1 class="title">SERVICIOS</h1>
          </div>
          <p class="p-lg text-center">
            El Centro de Arbitraje Empresarial como parte de su gama de servicios profesionales provee:
          </p>

          <div class="row justify-content-center box-services">
            <div class="col-sm-6 col-lg col-service">
              <div class="box">
                <div class="box-image">
                  <img src="public/images/pages/services/service-1.png">
                </div>

                <div class="box-text">
                  <div class="inside">
                    <h5>Mediación</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg col-service">
              <div class="box">
                <div class="box-image">
                  <img src="public/images/pages/services/service-2.png">
                </div>

                <div class="box-text">
                  <div class="inside">
                    <h5>Arbitraje</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg col-service">
              <div class="box">
                <div class="box-image">
                  <img src="public/images/pages/services/service-3.png">
                </div>

                <div class="box-text">
                  <div class="inside">
                    <h5>Conciliación</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg col-service">
              <div class="box">
                <div class="box-image">
                  <img src="public/images/pages/services/service-4.png">
                </div>

                <div class="box-text">
                  <div class="inside">
                    <h5>Pláticas y seminarios</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg col-service">
              <div class="box">
                <div class="box-image">
                  <img src="public/images/pages/services/service-5.png">
                </div>

                <div class="box-text">
                  <div class="inside">
                    <h5>Capacitaciones</h5>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
