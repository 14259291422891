<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title">Reglamento de mediación del CAE</h1>

    <h6>Artículo 1</h6>
    <ol>
      <li>Este Reglamento se aplicará a la mediación de controversias que deriven de una relación contractual u otro tipo de relación jurídica, o se vinculen con ella, cuando las partes que deseen llegar a una solución amistosa de su controversia hayan acordado aplicar el Reglamento de Mediación o del artículo 15 del Reglamento Arbitraje del Centro de Arbitraje Empresarial.</li>
      <li>Las partes podrán acordar, en cualquier momento, la exclusión o modificación de cualquiera de estas reglas.</li>
      <li>Cuando alguna de estas reglas esté en conflicto con una disposición de derecho que las partes no puedan derogar, prevalecerá esa disposición.</li>
    </ol>

    <h5>INICIO DEL PROCEDIMIENTO DE MEDIACIÓN</h5>
    <h6>Artículo 2</h6>
    <ul class="l-latin">
      <li>La mediación dará inició por aplicación del Artículo 15 del Reglamento de Arbitraje del Centro de Arbitraje Empresarial; o, cuando una parte o ambas partes así lo acuerden.</li>
      <li>Cuando no hay acuerdo previo, la parte que tome la iniciativa de la mediación enviará, por escrito, a la otra parte una invitación a la mediación de conformidad con el presente Reglamento, mencionando brevemente el asunto objeto de controversia.</li>
      <li>El procedimiento se iniciará cuando la otra parte acepte la invitación a la mediación. Si la aceptación se hiciere oralmente, es aconsejable que se confirme por escrito.</li>
      <li>Si la otra parte rechaza la mediación, no habrá procedimiento de mediación.</li>
      <li>Si la parte que inicia la mediación no recibe la aceptación de la otra parte dentro de los 30 días siguientes al envió de la invitación, o dentro del período de tiempo especificado en la invitación, tendrá la opción de considerar esa circunstancia como rechazo de la invitación a la mediación. Si decide considerarla como tal, deberá comunicarlo a la otra parte.</li>
    </ul>

    <h5>NUMERO DE MEDIADORES</h5>
    <h6>Artículo 3</h6>
    <p>
      En cualquier caso solo habrá un mediador, a menos que las partes acuerden que sean dos o tres los mediadores. Cuando haya más de un mediador deberán actuar conjuntamente.
    </p>

    <br />
    <h5>DESIGNACION DE LOS MEDIADORES</h5>
    <h6>Artículo 4</h6>
    <ul class="l-latin">
      <li>En el procedimiento de mediación con un mediador, el CAE designará uno en el momento de la admisión de la solicitud de mediación, a menos que las partes designen previamente a uno; en caso de no aceptar el sugerido por el CAE las partes procurarán ponerse de acuerdo sobre el nombre del mediador único;</li>
      <li>En el procedimiento de mediación con dos mediadores, cada una de las partes nombrará uno;</li>
      <li>En el procedimiento de mediación con tres mediadores, cada una de las partes nombrará uno. Las partes procurarán ponerse de acuerdo sobre el nombre del tercer mediador. Si no logran ponerse de acuerdo, los mediadores nombrados por las partes nombrarán al tercero.</li>
    </ul>

    <p class="mb-1">
      <strong>2. </strong>Las partes podrán recurrir a la asistencia del CAE en relación con el nombramiento del mediador, en los siguientes términos:
    </p>
    <ul class="mb-0 l-latin">
      <li>Una parte podrá solicitar a tal institución que recomiende los nombres de personas idóneas que podrían actuar como mediadores; o bien,</li>
      <li>Las partes podrán convenir en que el nombramiento de uno o más mediadores sea efectuado directamente por dicha institución.</li>
      <li>En caso de que pasadas 3 días y las partes no designarán uno o más mediadores de la lista de candidatos sugeridos por el CAE, el CAE designará, por economía procesal, uno de la lista y lo comunicará a las partes.</li>
      <li>Al formular recomendaciones o efectuar nombramientos de personas para el cargo de mediador, la institución tendrá en cuenta las consideraciones que puedan garantizar el nombramiento de un mediador independiente e imparcial y, con respecto a un mediador único o un tercer mediador, tendrá en cuenta la conveniencia de nombrar un mediador de nacionalidad distinta a las nacionalidades de las partes.</li>
    </ul>

    <br />
    <h5>PRESENTACION DE DOCUMENTOS AL MEDIADOR</h5>
    <h6>Artículo 5</h6>
    <ol>
      <li>El mediador, después de su designación, solicitará de cada una de las partes a través del CAE que le presente una sucinta descripción por escrito de la naturaleza general de la controversia y los puntos en litigio. Cada parte enviará a la otra un ejemplar de este documento al CAE.</li>
      <li>El mediador podrá solicitar de cada una de las partes una exposición adicional, por escrito, sobre su respectiva descripción inicial y sobre los hechos y motivos en que ésta se funda, acompañada de los documentos y otros medios de prueba que cada parte estime adecuados.</li>
      <li>El mediador podrá, en cualquier etapa del procedimiento de mediación, solicitar de una de las partes la presentación de otros documentos que estimare adecuados.</li>
    </ol>

    <h5>REPRESENTACIÓN Y ASESORAMIENTO</h5>
    <h6>Artículo 6</h6>
    <ol>
      <li>Las partes podrán hacerse representar o asesorar por personas de su elección. Los nombres y las direcciones de esas personas deberán comunicarse por escrito a la otra parte y al mediador; esa comunicación deberá precisar si la designación se hace a efectos de representación o de asesoramiento.</li>
      <li>En caso de que los asesores designados por las partes también participen en las audiencias de mediación deberán guardar absoluta compostura, respeto y sumisión al procedimiento; el mediador tendrá plenas facultades para pedir al o a los asesores que se retiren de la mediación si su comportamiento no es adecuado.</li>
    </ol>

    <h5>FUNCION DEL MEDIADOR</h5>
    <h6>Artículo 7</h6>
    <ol>
      <li>El mediador ayudará a las partes de manera independiente e imparcial en sus esfuerzos por lograr un arreglo amistoso de la controversia.</li>
      <li>El mediador se atendrá a principios de objetividad, equidad y justicia, teniendo en cuenta, entre otros factores, los derechos y las obligaciones de las partes, los usos del tráfico mercantil de que se trate y las circunstancias de la controversia, incluso cualesquiera prácticas establecidas entre las partes.</li>
      <li>El mediador podrá conducir el procedimiento de mediación en la forma que estime adecuada, teniendo en cuenta las circunstancias del caso, los deseos que expresen las partes, incluida la solicitud de cualesquiera de ellas de que el mediador oiga declaraciones orales, y la necesidad de lograr un rápido arreglo de la controversia.</li>
      <li>El mediador podrá, en cualquier etapa del procedimiento de mediación, formular propuestas para una transacción de la controversia. No es preciso que esas propuestas sean formuladas por escrito ni que se aplique el fundamento de ellas.</li>
    </ol>

    <h5>ASISTENCIA ADMINISTRATIVA</h5>
    <h6>Artículo 8</h6>
    <p>
      Con el fin de facilitar el desarrollo del procedimiento de mediación, las partes, o el mediador con el consentimiento de éstas, podrán disponer la prestación de asistencia administrativa del CAE.
    </p>

    <br />
    <h5>COMUNICACIONES ENTRE EL MEDIADOR Y LAS PARTES</h5>
    <h6>Artículo 9</h6>
    <ol>
      <li>El mediador podrá invitar a las partes a reunirse con él o comunicarse con ellas oralmente o por escrito. Podrá reunirse o comunicarse con las partes, ya sea conjuntamente o con cada una de ellas por separado.</li>
      <li>A falta de acuerdo entre las partes respecto del lugar en que hayan de reunirse con el mediador, éste determinará el lugar, previa consulta con las partes, teniendo en consideración las circunstancias del procedimiento de mediación.</li>
    </ol>

    <h5>DIVULGACION DE INFORMACION</h5>
    <h6>Artículo 10</h6>
    <p>
      Si el mediador recibe de una de las partes información de hechos relativos a la controversia, divulgará su contenido a la otra parte a fin de que ésta pueda presentarle las explicaciones que estime convenientes. Sin embargo, si una parte proporciona información al mediador bajo la condición expresa de que se mantenga confidencial, el mediador no divulgará esa información.<br />
      <br />
      Para todos los efectos, ni el mediador, ni el CAE podrá divulgar ningún tipo de información que esté relacionada con un procedimiento, incluso cuando este ya haya concluido; solamente las partes podrán divulgar o autorizar la divulgación de alguna información.
    </p>

    <br />
    <h5>COLABORACION DE LAS PARTES CON EL MEDIADOR</h5>
    <h6>Artículo 11</h6>
    <p>
      Las partes colaborarán de buena fe con el mediador y, en particular, se esforzarán en cumplir las solicitudes de éste de presentar documentos escritos, aportar pruebas y asistir a las reuniones.
    </p>

    <br />
    <h5>SUGERENCIAS DE LAS PARTES PARA LA TRANSACCION DE LA CONTROVERSIA</h5>
    <h6>Artículo 12</h6>
    <p>
      Cada una de las partes, a iniciativa propia o a invitación del mediador, podrá presentar a éste sugerencias para la transacción de la controversia.
    </p>

    <br />
    <h5>ACUERDO DE TRANSACCION</h5>
    <h6>Artículo 13</h6>
    <ol>
      <li>Cuando el mediador estime que existen elementos para una transacción aceptable para las partes, formulará los términos de un proyecto de transacción y los presentará a las partes para que éstas expresen sus observaciones. A la vista de estas observaciones, el mediador podrá formular nuevamente otros términos de posible transacción.</li>
      <li>Si las partes llegan a un acuerdo sobre la transacción de la controversia redactarán y firmarán un acuerdo escrito de transacción. Si las partes así lo solicitan, el mediador redactará el acuerdo de transacción o ayudará a las partes a redactarlo.</li>
      <li>Las partes, al firmar el acuerdo de transacción, pondrán fin a la controversia y quedarán obligadas al cumplimiento del acuerdo.</li>
    </ol>

    <h5>CONFIDENCIALIDAD</h5>
    <h6>Artículo 14</h6>
    <p>
      El mediador y las partes mantendrán el carácter confidencial de todas las cuestiones relativas al procedimiento de mediación. La confidencialidad se hará también extensiva a los acuerdos de transacción, salvo en los casos en que su revelación sea necesaria con fines de ejecución y cumplimiento.
    </p>

    <br />
    <h5>CONCLUSION DEL PROCEDIMIENTO DE MEDIACIÓN</h5>
    <h6>Artículo 15</h6>
    <p>
      El procedimiento de mediación concluirá:
    </p>
    <ul>
      <li>Por la firma de un acuerdo de transacción por las partes, en la fecha del acuerdo; o</li>
      <li>Por una declaración escrita del mediador hecha después de efectuar consultas con las partes en el sentido de que ya no se justifican ulteriores esfuerzos de mediación, en la fecha de la declaración; o</li>
      <li>Por una declaración escrita dirigida al mediador por las partes en el sentido de que el procedimiento de mediación queda concluido, en la fecha de la declaración; o</li>
      <li>Por una notificación escrita dirigida por una de las partes a la otra parte y al mediador, si éste ha sido designado, en el sentido de que el procedimiento de mediación queda concluido, en la fecha de la declaración.</li>
      <li>Cuando a juicio del mediador o a solicitud de parte, se pueda concluir que alguna de las partes está usando el procedimiento mediatorio o conciliatorio para dilatar un procedimiento arbitral o judicial, en términos del Reglamento del Arbitraje del CAE, podrá dar por concluido el procedimiento mediatorio o conciliatorio.</li>
    </ul>

    <h5>RECURSO A PROCEDIMIENTOS ARBITRALES O JUDICIALES</h5>
    <h6>Artículo 16</h6>
    <p>
      Las partes se comprometen a no iniciar, durante el procedimiento de mediación, ningún procedimiento arbitral o judicial respecto de una controversia que sea objeto del procedimiento de mediación, con la salvedad de que una parte podrá iniciar un procedimiento arbitral o judicial cuando ésta estime que tal procedimiento es necesario para conservar sus derechos.<br />
      <br />
      A juicio del mediador o a solicitud de alguna de las partes, si una de las partes estuviera usando el procedimiento mediatorio o conciliatorio para dilatar un procedimiento arbitral o judicial podrá, en términos del Reglamento del Arbitraje del CAE y del artículo 15 de este Reglamento dar por concluido el procedimiento.
    </p>

    <br />
    <h5>COSTAS</h5>
    <h6>Artículo 17</h6>
    <ol>
      <li>
        <p>Al terminar el procedimiento de mediación, el mediador determinará las costas de la mediación y las notificará por escrito a las partes. El término "costas" comprende exclusivamente:</p>
        <ul class="mt-1 l-latin">
          <li>Las tasas de administración del CAE;</li>
          <li>Los honorarios del mediador;</li>
          <li>Los gastos de viaje y demás gastos del mediador;</li>
          <li>Los gastos de viaje y demás gastos de cualesquiera testigos que hubiera llamado el mediador con el consentimiento de las partes;</li>
          <li>El costo de todo asesoramiento pericial solicitado por el mediador con el consentimiento de las partes;</li>
          <li>El costo de la asistencia proporcionada de conformidad con el artículo 4, párrafo 2 b) y con el artículo 8 del presente Reglamento.</li>
        </ul>
      </li>
      <li>Las costas señaladas en el párrafo precedente se dividirán por igual entre las partes, salvo que el acuerdo de transacción disponga una distribución distinta. Todos los otros gastos en que incurra una parte serán por cuenta de ella.</li>
    </ol>

    <h5>ANTICIPOS</h5>
    <h6>Artículo 18</h6>
    <ol>
      <li>El CAE anticipará a la solicitud de Mediación las tasas de administración y el anticipo de honorarios del Mediador de acuerdo al arancel que se establezca por el Consejo General del CAE</li>
      <li>El CAE una vez designado el mediador, podrá requerir de cada una de las partes que consigne una suma igual en concepto de anticipo de las costas que, de conformidad con el párrafo 1 del artículo 17, éste calcule que podrán causarse.</li>
      <li>En el curso del procedimiento de mediación, el mediador podrá solicitar anticipos adicionales de igual valor a cada una de las partes.</li>
      <li>Si las sumas cuya consignación es requerida de conformidad con los párrafos 1 y 2 de este artículo no hubieran sido abonadas en su totalidad por ambas partes dentro del plazo de 30 días, el mediador podrá suspender el procedimiento o presentar a las partes una declaración escrita de conclusión, que entrará en vigor en la fecha en que se haya formulado.</li>
      <li>Una vez concluidos los procedimientos de mediación, el mediador rendirá cuentas a las partes de los anticipos recibidos y les reembolsará cualquier saldo que resulte a favor de éstas.</li>
    </ol>

    <h5>FUNCION DEL MEDIADOR EN OTROS PROCEDIMIENTOS</h5>
    <h6>Artículo 19</h6>
    <ol>
      <li>Las partes y el mediador se comprometen a que el mediador no actúe como representante o asesor de una parte en ningún procedimiento arbitral o judicial relativo a una controversia que hubiera sido objeto del procedimiento de mediación. </li>
      <li>Las partes se comprometen, además, a no llamar al mediador como testigo en ninguno de tales procedimientos.</li>
      <li>El mediador si podrá actuar como amigable componedor cuando así lo designen las partes.</li>
    </ol>

    <h5>RESPONSABILIDAD DEL MEDIADOR</h5>
    <h6>Artículo 20</h6>
    <p>
      El mediador, el Centro de Arbitraje Empresarial, y los representantes de éste, no serán responsables ante ninguna de las partes o ante sus representantes, por ningún acto u omisión relacionado directa o indirectamente con la mediación salvo por dolo patente, culpa grave, negligencia inexcusable o violación de confidencialidad conforme a lo previsto en el presente reglamento.
    </p>
  </div>
</template>

<script>
export default {
}
</script>
