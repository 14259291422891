<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title">Reglamento de arbitraje expedito y de baja cuantía</h1>

    <h5>DISPOSICIONES GENERALES</h5>
    <h6>Art. 1. Ámbito de aplicación.</h6>
    <p>
      Serán aplicables las disposiciones del presente Reglamento cuando:
    </p>
    <ol>
      <li>Las partes lo pacten expresamente o utilicen expresiones que denoten su intención de someter la controversia al arbitraje expedito y de baja cuantía del CAE;</li>
      <li>Las partes hayan pactado recurrir al arbitraje CAE o utilicen expresiones que denoten su intención de someter la controversia al arbitraje del CAE y la cuantía sea igual o inferior a $1,000,000.00 pesos (un millón de pesos 00/100 M.N.); y</li>
      <li>De existir diferencia sobre el monto de la controversia, el CAE determinará si es aplicable el Reglamento de Arbitraje del CAE (Reglamento de Arbitraje) o el Reglamento de Arbitraje Expedito y de Baja Cuantía del CAE.</li>
    </ol>

    <h6>Art. 2. Remisión al Reglamento de Arbitraje.</h6>
    <p>
      El presente Reglamento contiene disposiciones especiales para arbitrajes expeditos y de baja cuantía. En todo lo no previsto por este, se estará al Reglamento de Arbitraje.
    </p>

    <br />
    <h5>DEMANDA Y CONTESTACIÓN</h5>
    <h6>Art. 3. Demanda de arbitraje.</h6>
    <ol>
      <li>La parte que recurra al arbitraje de conformidad con este Reglamento, deberá presentar su demanda de arbitraje ante el CAE.</li>
      <li>
        <p>La demanda de arbitraje contendrá lo siguiente:</p>
        <ul class="l-latin">
          <li>el nombre de las partes y sus datos de contacto;</li>
          <li>una referencia al acuerdo de arbitraje que se invoca;</li>
          <li>una exposición de los hechos en los que se base la demanda;</li>
          <li>los puntos en litigio;</li>
          <li>las pretensiones de la Actora incluyendo, en la medida de lo posible, la indicación del monto de la controversia;</li>
          <li>la propuesta de la Actora con relación al nombramiento del árbitro único;</li>
          <li>la propuesta de la Actora con relación al lugar del arbitraje, el derecho aplicable al fondo y el idioma del arbitraje, cuando las partes no hayan convenido sobre ello.</li>
        </ul>
      </li>
      <li>
        A la demanda de arbitraje deberá acompañarse (i) copia del contrato o instrumento jurídico que haya dado origen a la controversia o que tenga vinculación con ella; (ii) copia del acuerdo de arbitraje, si no está incluido en el instrumento jurídico base de la acción; y, (iii) en la medida de lo posible, las pruebas en que se funde la demanda de la Actora, que solo podrán ser documentales. De ser el caso, las declaraciones de testigos y los reportes de peritos solo se recibirán si se producen por escrito.
      </li>
    </ol>

    <h6>Art. 4. Contestación y reconvención.</h6>
    <ol>
      <li>La Demandada deberá presentar su contestación y, en su caso, la reconvención, en un plazo de 10 días naturales contados a partir de la recepción de la demanda de arbitraje notificada por el CAE. </li>
      <li>
        <p>La contestación deberá contener lo siguiente:</p>
        <ul class="l-latin">
          <li>el nombre de las partes y los datos de contacto de la Demandada;</li>
          <li>una respuesta a los hechos en los que la Actora basa la demanda; </li>
          <li>las observaciones de la Demandada con relación a los puntos en litigio señalados por la Actora;</li>
          <li>las observaciones de la Demandada con relación a las pretensiones de la Actora;</li>
          <li>las observaciones de la Demandada sobre la propuesta de la Actora con relación al nombramiento del árbitro único o bien, su propuesta a este respecto;</li>
          <li>las observaciones de la Demandada sobre la propuesta de la Actora con relación al lugar del arbitraje, el derecho aplicable al fondo y el idioma del arbitraje, o bien, su propuesta a este respecto, cuando las partes no hayan convenido sobre ello. </li>
        </ul>
      </li>
      <li>A la contestación deberá acompañarse, en la medida de lo posible, todos los documentos y demás materiales probatorios que ofrezca la Demandada. De ser el caso, las declaraciones de testigos y los reportes de peritos solo se recibirán si se producen por escrito.</li>
      <li>En caso de reconvención, esta deberá contener los mismos requisitos que la demanda de arbitraje y deberá ser contestada por la Actora en un plazo de 10 días naturales contados a partir de su recepción.</li>
    </ol>

    <h5>TRIBUNAL ARBITRAL</h5>
    <h6>Art. 5. Árbitro único.</h6>
    <p>
      El tribunal arbitral se integrará por un solo árbitro.
    </p>

    <br />
    <h6>Art. 6. Nombramiento del árbitro.</h6>
    <p>
      A menos que las partes hayan llegado a un acuerdo con respecto al nombramiento del árbitro una vez transcurrido el plazo para la presentación de la contestación de la demanda, el árbitro será nombrado por el CAE tan pronto sea posible.
    </p>

    <br />
    <h5>PROCEDIMIENTO ARBITRAL</h5>
    <h6>Art. 7. Conferencia preparatoria.</h6>
    <ol>
      <li>Una vez nombrado el árbitro, este convocará a las partes a una conferencia telefónica tan pronto sea posible, para la organización del procedimiento arbitral.</li>
      <li>El árbitro, escuchando a las partes, determinará si estas podrán presentar escritos adicionales y fijará los plazos para ello.</li>
    </ol>

    <h6>Art. 8. Decisión sobre documentos.</h6>
    <p>
      Las actuaciones se sustanciarán sobre la base de documentos, a menos que en circunstancias especiales, el árbitro, escuchando a las partes, considere justificado que puedan ofrecerse otras pruebas.
    </p>

    <br />
    <h6>Art. 9. Audiencia.</h6>
    <ol>
      <li>Una vez recibidos los escritos a que se refieren los párrafos anteriores, el árbitro convocará a las partes a una audiencia fijando el día, hora y lugar. </li>
      <li>La audiencia deberá llevarse a cabo dentro del plazo más breve posible considerando las circunstancias del caso.</li>
      <li>Al término de la audiencia, el árbitro podrá fijar un plazo común a las partes para la presentación de alegatos finales. </li>
      <li>Las partes podrán convenir que no se celebre la audiencia y que el árbitro resuelva con los escritos y documentos presentados por las partes. </li>
    </ol>

    <h6>Art. 10. Cierre de la instrucción.</h6>
    <p>
      El árbitro declarará cerrada la instrucción una vez que las partes hayan tenido plena oportunidad de presentar sus pruebas y argumentos. Hecha esta declaración, las partes no podrán presentar pruebas adicionales ni alegar, a menos que el árbitro lo solicite o autorice expresamente.
    </p>

    <br />
    <h6>Art. 11. Laudo. </h6>
    <ol>
      <li>El árbitro dictará el laudo por escrito y lo entregará al CAE para que lo notifique.</li>
      <li>El laudo no necesariamente contendrá razones.</li>
      <li>El laudo se considera dictado en el lugar del arbitraje, en la fecha que ostente.</li>
    </ol>

    <h5>COSTAS</h5>
    <h6>Art. 12. Costas del arbitraje.</h6>
    <ol>
      <li>
        <p>1.	Las costas del arbitraje expedito y de baja cuantía incluyen:</p>
        <ul class="l-latin">
          <li>los honorarios del árbitro; </li>
          <li>la cuota administrativa del CAE;</li>
          <li>en su caso, los gastos del árbitro; y</li>
          <li>los gastos en que razonablemente hubieren incurrido las partes para la defensa de sus intereses en el procedimiento arbitral.</li>
        </ul>
      </li>
      <li>Corresponde al CAE fijar los montos a que se refieren los incisos (a), (b) y (c) del párrafo anterior conforme al Arancel de costos previsto en el Anexo de este Reglamento. </li>
    </ol>

    <h6>Art. 13. Anticipo sobre costas. </h6>
    <ol>
      <li>Toda demanda de arbitraje presentada en los términos del artículo 3 de este Reglamento deberá ir acompañada del pago de la cantidad establecida en el Arancel de costos contenido en el Anexo del presente Reglamento.</li>
      <li>Dicho anticipo será abonado a la provisión de gastos que para tal efecto fije el CAE de conformidad con el Arancel de costos. </li>
      <li>No será tomada en consideración ninguna demanda de arbitraje que no vaya acompañada del pago de este anticipo, que será percibido definitivamente por el CAE y no será reembolsable. </li>
    </ol>

    <h5 class="text-center">ANEXO</h5>
    <h6 class="text-center">Arancel de costos*<br />(pesos mexicanos)</h6>
    <ol>
      <li>
        <p>El costo del arbitraje se determinará sobre la base siguiente:</p>

        <table class="mt-2 table table-sm">
          <thead>
            <tr>
              <th>Monto de la controversia</th>
              <th>Cuota administrativa</th>
              <th>Honorarios del árbitro único</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hasta 500,000</td>
              <td>5,000</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>De 500,001 a 1,000,000</td>
              <td>7,500</td>
              <td>15,000</td>
            </tr>
          </tbody>
        </table>
        <p>*Estos montos no incluyen el Impuesto al Valor Agregado (IVA). </p>
      </li>
      <li>El anticipo sobre costas a que se refiere el artículo 13 del presente Reglamento es de $5,000.00 pesos.</li>
      <li>Los gastos del árbitro único, en su caso, no podrán exceder de $3,000.00 pesos.</li>
      <li>Este arancel podrá ser modificado en cualquier momento por el CAE. La versión aplicable será la que esté en vigor al momento del inicio del procedimiento arbitral. </li>
    </ol>

    <h5>CLÁUSULA MODELO DE ARBITRAJE EXPEDITO Y DE BAJA CUANTÍA</h5>
    <p class="f-w-600">
      <i>“Todas las controversias que deriven de o guarden relación con este contrato serán resueltas definitivamente de acuerdo con el Reglamento de Arbitraje Expedito y de Baja Cuantía del Centro de Arbitraje Empresarial, por un árbitro nombrado conforme a dicho Reglamento.”</i>
    </p>
    <p class="mt-2">
      Adicionalmente, las partes podrán estipular en la cláusula de arbitraje el derecho aplicable al fondo, el lugar del arbitraje y el idioma del procedimiento arbitral.
    </p>

    <br />
    <h5>Transitorios</h5>
    <ol>
      <li>Las disposiciones del presente Reglamento entrarán en vigor a partir del 20 de noviembre de 2014.</li>
      <li>Los procedimientos arbitrales que se encuentren pendientes ante el CAE a la fecha de entrada en vigor del presente Reglamento se atendrán a lo dispuesto por el Reglamento vigente al momento de su inicio.</li>
    </ol>
  </div>
</template>

<script>
export default {
}
</script>
